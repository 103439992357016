import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ReactPhoneNumberInput from 'react-phone-number-input';
import { makeStyles } from 'tss-react/mui';
import 'react-phone-number-input/style.css';
import Input from 'eventtia-ui-components/lib/Input';
import withFormField from 'eventtia-ui-components/lib/hocs/withFormField';

const InputBase = forwardRef(({ onChange, ...otherProps }, ref) => (
  <Input handleChange={onChange} inputRef={ref} {...otherProps} />
));

InputBase.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const useStyles = makeStyles()((theme) => ({
  root: {
    '& .PhoneInputCountry': {
      padding: theme.spacing(0, 2),
      borderRadius: 5,
      backgroundColor: theme.palette.lightGrey.main,
      marginRight: theme.spacing(1),
    },
    '& div:nth-of-type(2)': {
      flex: 1,
    },
  },
}));

/** This component must be used as a controlled input. */
const PhoneInput = ({ value, handleChange, className, ...other }) => {
  const { classes, cx } = useStyles();
  return (
    <ReactPhoneNumberInput
      value={value}
      onChange={handleChange}
      inputComponent={InputBase}
      className={cx(classes.root, className)}
      {...other}
    />
  );
};

PhoneInput.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

PhoneInput.defaultProps = {
  className: undefined,
};

export default withFormField({ messagePosition: 'bottom' })(PhoneInput);
