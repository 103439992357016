import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { defaultLanguage } from '../locales';
import { prependLocalePath } from './index';
import AsyncPage from '../hocs/AsyncPage';
import AsyncLayout from '../hocs/AsyncLayout';
import AttendeesFilters from '../components/AttendeesFilters';
import EventsFilters from '../components/EventFilters';
import SubscriptionGuard from './SubscriptionGuard';
import TemplateB from '../Composer/TemplateB';
import GuestGuard from './GuestGuard';
import AuthGuard from './AuthGuard';

const router = createBrowserRouter([
  { path: '/', element: <Navigate to={defaultLanguage} /> },
  { path: '*', element: <div>404</div> },
  {
    path: prependLocalePath('*'),
    children: [
      {
        element: (
          <GuestGuard>
            <AsyncLayout page="LoggedOutLayout" />
          </GuestGuard>
        ),
        children: [
          {
            path: 'login',
            element: <AsyncPage page="Login" />,
          },
          {
            path: 'sign-up',
            element: <AsyncPage page="SignUp" />,
          },
          {
            path: 'activate-account/:activationToken',
            element: <AsyncPage page="ActivateAccount" />,
          },
          {
            path: 'forgot-password',
            element: <AsyncPage page="ForgotPassword" />,
          },
          {
            path: 'change-password/:token',
            element: <AsyncPage page="ChangePassword" />,
          },
          {
            path: 'set-password/:token',
            element: <AsyncPage page="ChangePassword" isNewUser />,
          },
          {
            path: '*',
            element: <Navigate to="login" />,
          },
          // this will not be in this flow
          // {
          //   path: 'payment-form',
          //   element: <AsyncPage page="PaymentForm" />,
          // },
        ],
      },
      {
        element: (
          <AuthGuard>
            <SubscriptionGuard>
              <AsyncLayout page="LoggedInLayout" />
            </SubscriptionGuard>
          </AuthGuard>
        ),
        children: [
          {
            element: <AsyncLayout page="MainSectionLayout" />,
            children: [
              {
                path: '*',
                element: <Navigate to="events" />,
              },
              {
                path: 'events/*',
                element: <AsyncPage page="EventsIndex" />, // must have Outlet for rendering drawers
                children: [
                  // these are drawers
                  {
                    path: 'new/*',
                    element: <AsyncPage page="EventCreate" />,
                    children: [
                      {
                        path: 'new-area',
                        element: <AsyncPage page="AreaCreate" />,
                      },
                    ],
                  },
                  {
                    path: 'filter',
                    element: <EventsFilters />,
                  },
                ],
              },
              {
                path: 'team/*',
                element: <AsyncPage page="TeamIndex" />, // must have Outlet for rendering sections
                children: [
                  // these are drawers
                  {
                    path: '*',
                    element: <Navigate to="users" />,
                  },
                  {
                    path: 'users/*',
                    element: <AsyncPage page="Users" />,
                    children: [
                      {
                        path: 'new/*',
                        element: <AsyncPage page="UserCreate" />,
                        children: [
                          {
                            path: 'new-area',
                            element: <AsyncPage page="AreaCreate" />,
                          },
                          {
                            path: 'new-role',
                            element: <AsyncPage page="RoleCreate" />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'roles',
                    element: <AsyncPage page="Roles" />,
                    children: [
                      {
                        path: 'new',
                        element: <AsyncPage page="RoleCreate" />,
                      },
                    ],
                  },
                  {
                    path: 'areas',
                    element: <AsyncPage page="Areas" />,
                    children: [
                      {
                        path: 'new',
                        element: <AsyncPage page="AreaCreate" />,
                      },
                    ],
                  },
                  // {
                  //   path: 'areas',
                  //   element: 'areas',
                  // },
                ],
              },
            ],
          },
          {
            element: <AsyncLayout page="MainSectionModuleLayout" />,
            children: [
              {
                path: 'team/users/:userId',
                element: <AsyncPage page="UserEdit" />,
                children: [
                  {
                    path: 'new-area',
                    element: <AsyncPage page="AreaCreate" />,
                  },
                  {
                    path: 'new-role',
                    element: <AsyncPage page="RoleCreate" />,
                  },
                ],
              },
              {
                path: 'team/roles/:roleId',
                element: <AsyncPage page="RoleEdit" />,
              },
              {
                path: 'team/areas/:areaId',
                element: <AsyncPage page="AreaEdit" />,
              },
              {
                path: 'profile/*',
                element: <AsyncPage page="MyAccount" />,
                children: [
                  {
                    path: '*',
                    element: <Navigate to="info" />,
                  },
                  {
                    path: 'info',
                    element: <AsyncPage page="AccountEdit" />,
                  },
                  {
                    path: 'security',
                    element: <AsyncPage page="AccountSecurity" />,
                  },
                  {
                    path: 'subscription',
                    element: <AsyncPage page="AccountSubscription" />,
                    children: [
                      {
                        path: 'plans',
                        element: <AsyncPage page="UserPlans" />,
                      },
                      {
                        path: 'success',
                        element: <AsyncPage page="SuccessPayment" />,
                      },
                      {
                        path: 'failed',
                        element: <AsyncPage page="FailedPayment" />,
                      },
                      {
                        path: 'onboarding-success',
                        element: <AsyncPage page="OnboardingSuccess" />,
                      },
                      {
                        path: 'onboarding-fail',
                        element: <AsyncPage page="OnboardingFail" />,
                      },
                    ],
                  },
                  {
                    path: 'resources',
                    element: <AsyncPage page="AccountResources" />,
                  },
                ],
              },
            ],
          },
          // event show and event module indexes
          {
            path: 'events/:eventUri/*',
            element: <AsyncLayout page="EventLayout" />,
            children: [
              {
                path: '*',
                element: <Navigate to="dashboard" />,
              },
              {
                path: 'dashboard',
                element: <AsyncPage page="EventDashboard" />,
              },
              {
                path: 'widget',
                element: <AsyncPage page="WidgetSettings" />,
              },
              {
                path: 'tickets/*',
                element: <AsyncPage page="TicketTypesIndex" />, // must have outlet for rendering drawer
                children: [
                  // TODO: drawers
                  {
                    path: 'new',
                    element: <AsyncPage page="TicketTypeCreate" />, // this is the drawer
                  },
                  {
                    path: 'fields',
                    element: <AsyncPage page="FieldsLibrary" />, // this is the drawer
                    children: [
                      {
                        path: 'new', // this is the drawer
                        element: <AsyncPage page="FieldsCreate" />,
                      },
                      {
                        path: ':fieldId/edit', // this is the drawer
                        element: <AsyncPage page="FieldEdit" />,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'attendees/*',
                element: <AsyncPage page="AttendeesIndex" />, // this must have an outlet that will render drawers
                children: [
                  // these are drawers
                  // TODO: drawers
                  {
                    path: 'new', // this is the drawer
                    element: <AsyncPage page="AttendeeCreate" />,
                  },
                  {
                    path: 'filter',
                    element: <AttendeesFilters />,
                  },
                ],
              },
              {
                path: 'emails/*',
                element: <AsyncPage page="EventEmails" />,
                children: [
                  // these are drawers
                  {
                    path: 'new',
                    element: <AsyncPage page="EventEmailCreate" />,
                  },
                  {
                    path: 'sender-settings',
                    element: <AsyncPage page="EventEmailSenderSettings" />,
                  },
                ],
              },
              {
                path: 'payments',
                element: <AsyncPage page="Payments" />,
              },
              {
                path: 'settings',
                element: <AsyncPage page="EventSettings" />,
              },
              // TODO: ...speakers, activities, etc
              {
                path: 'orders/*',
                element: <AsyncPage page="OrdersIndex" />, // must have outlet for rendering drawer
                children: [
                  {
                    path: ':orderUuid/*',
                    element: <AsyncPage page="OrderShow" />,
                  },
                ],
              },
            ],
          },
          // ticket show <- aqui hay problema
          {
            element: <AsyncLayout page="ModuleShowLayout" />,
            children: [
              {
                // ticket show <- aqui hay problema???
                path: 'events/:eventUri/tickets/:ticketId/*',
                element: <AsyncPage page="TicketTypeShow" />, // TODO: should this be moved to layouts?
                children: [
                  {
                    path: '*',
                    element: <Navigate to="info" />,
                  },
                  {
                    path: 'info',
                    element: <AsyncPage page="TicketTypeEdit" />,
                  },
                  {
                    path: 'registration',
                    element: <AsyncPage page="TicketTypeRegistration" />,
                    children: [
                      {
                        path: 'add-field',
                        element: <AsyncPage page="FieldsLibrary" />,
                        children: [
                          {
                            path: 'new', // this is the drawer
                            element: <AsyncPage page="FieldsCreate" />,
                          },
                          {
                            path: ':fieldId/edit', // this is the drawer
                            element: <AsyncPage page="FieldEdit" />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'emails',
                    element: <AsyncPage page="TicketTypeEmails" />,
                  },
                  {
                    path: 'advanced',
                    element: <AsyncPage page="TicketTypeAdvancedOptions" />,
                    // TODO: drawers
                  },
                ],
              },
            ],
          },
          // attendee show <- aqui hay problema
          {
            element: <AsyncLayout page="ModuleShowLayout" />,
            children: [
              {
                // attendee show <- aqui hay problema???
                path: 'events/:eventUri/attendees/:attendeeId/*',
                element: <AsyncPage page="AttendeeShow" />, // this must have an Outlet that will render for each tab
                children: [
                  // these are the content inside each tab
                  {
                    path: '*',
                    element: <Navigate to="info" />,
                  },
                  {
                    path: 'info',
                    element: <AsyncPage page="AttendeeEdit" />,
                  },
                  {
                    path: 'emails',
                    element: <div>emails component</div>,
                  },
                  {
                    path: 'activities',
                    element: <div>activities component</div>,
                  },
                  {
                    path: 'payments',
                    element: <div>payments component</div>,
                    children: [
                      // these are drawers
                      // TODO: drawers
                      {
                        path: 'new', // this is the drawer
                        element: <div>new payment drawer</div>,
                      },
                    ],
                  },
                  {
                    path: 'networking',
                    element: <div>networking component</div>,
                  },
                ],
              },
              {
                path: 'events/:eventUri/emails/:emailId/*',
                element: <AsyncPage page="EventEmailShow" />, // this must have an Outlet for the settings drawer
                children: [
                  {
                    path: 'settings',
                    element: <AsyncPage page="EventEmailSettings" />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  { path: prependLocalePath('template-b/:eventUri'), element: <TemplateB /> },
]);

const getFullPaths = (routes, prefix = '') => {
  const result = [];
  routes.forEach(({ path = '', children }) => {
    const suffix = path.endsWith('*') ? '' : '/';
    const currentPath = `${prefix}${path.replace('*', '')}${suffix}`.replace(
      '//',
      '/'
    );
    result.push({ path: currentPath });
    if (children) result.push(...getFullPaths(children, currentPath));
  });
  return result;
};

const paths = [...getFullPaths(router.routes)];

export { router, paths };
