import React, { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

import Drawer from 'eventtia-ui-components/lib/Drawer';
import Typography from '@mui/material/Typography';
import Input from 'eventtia-ui-components/lib/Input';
import Divider from '@mui/material/Divider';
import SelectInput from 'eventtia-ui-components/lib/SelectInput';
import DateInput from 'eventtia-ui-components/lib/DateInput';
import Button from 'eventtia-ui-components/lib/Button';

import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import formSchemas from '../../helpers/formSchemas';
import useCustomForm from '../../hooks/useCustomForm';
import usePrefetchEvent from '../../hooks/usePrefetchEvent';
import useQuery from '../../hooks/useQuery';

const useStyles = makeStyles()((theme) => ({
  divider: {
    margin: theme.spacing(4, 0),
  },
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  drawerContent: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(5, 4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.5, 2, 0),
      margin: theme.spacing(-4),
    },
  },
  templateContent: {
    display: 'grid',
    gridRow: 'repeat(3, auto)',
    gridTemplateAreas: `
      'byKeyword'
      'byTicket'
      'byDate'
    `,
  },
  title: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      paddingTop: theme.spacing(2),
    },
  },
  keywordSearch: {
    gridArea: 'byKeyword',
    display: 'grid',
    gridTemplateRows: 'repeat(4, auto)',
    gridTemplateColumns: 'repeat(2, auto)',
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(3),
    gridTemplateAreas: `
      'keywordTitle .'
      'firstName lastName'
      'email email'
      'clearButton .'
    `,
    [theme.breakpoints.down('md')]: {
      gridTemplateRows: 'repeat(5, auto)',
      gridTemplateColumns: 'repeat(1, auto)',
      columnGap: theme.spacing(3),
      rowGap: theme.spacing(3),
      gridTemplateAreas: `
        'keywordTitle'
        'firstName'
        'lastName'
        'email'
        'clearButton'
      `,
    },
  },
  keywordTitle: {
    gridArea: 'keywordTitle',
    marginBottom: theme.spacing(0),
    color: theme.palette.darkGrey.light,
  },
  firstName: {
    gridArea: 'firstName',
  },
  lastName: {
    gridArea: 'lastName',
  },
  email: {
    gridArea: 'email',
  },
  clear: {
    gridArea: 'clearButton',
    width: 'fit-content',
    height: 'fit-content',
    paddingLeft: theme.spacing(0),
    marginLeft: theme.spacing(-3),
    fontWeight: 'normal',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.light,
    },
  },
  ticketSearch: {
    gridArea: 'byTicket',
    display: 'grid',
    gridTemplateRows: 'repeat(2, auto)',
    gridTemplateColumns: 'repeat(1, auto)',
    rowGap: theme.spacing(3),
    gridTemplateAreas: `
      'ticketTitle'
      'ticketContent'
    `,
  },
  ticketTitle: {
    gridArea: 'ticketTitle',
    marginBottom: theme.spacing(0),
    color: theme.palette.darkGrey.light,
  },
  ticketContent: {
    gridArea: 'ticketContent',
  },
  dateSearch: {
    paddingBottom: theme.spacing(4),
    gridArea: 'byDate',
    display: 'grid',
    gridTemplateRows: 'repeat(3, auto)',
    gridTemplateColumns: 'repeat(2, auto)',
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(3),
    gridTemplateAreas: `
      'dateTitle .'
      'from to'
      'clearButton .'
    `,
    [theme.breakpoints.down('md')]: {
      gridTemplateRows: 'repeat(4, auto)',
      gridTemplateColumns: 'repeat(1, auto)',
      columnGap: theme.spacing(3),
      rowGap: theme.spacing(3),
      gridTemplateAreas: `
        'dateTitle'
        'from'
        'to'
        'clearButton'
      `,
    },
  },
  dateTitle: {
    gridArea: 'dateTitle',
    marginBottom: theme.spacing(0),
    color: theme.palette.darkGrey.light,
  },
  dateFrom: {
    gridArea: 'from',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  dateTo: {
    gridArea: 'to',
  },
  clearDate: {
    gridArea: 'clearButton',
    width: 'fit-content',
    height: 'fit-content',
    paddingLeft: theme.spacing(0),
    marginLeft: theme.spacing(-3.5),
    fontWeight: 'normal',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.light,
      fontWeight: 'lighter',
    },
  },
  filterActionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'sticky',
    bottom: 0,
    right: 0,
    background: theme.palette.common.white,
    margin: theme.spacing(-4),
    padding: theme.spacing(1.5, 3),
    borderRadius: '0px 0px 5px 5px',
    [theme.breakpoints.down('md')]: {
      display: 'grid',
      gridTemplateAreas: `
      'filter'
      'clear'
      `,
      gridTemplateColumns: '100%',
      rowGap: theme.spacing(1.5),
    },
  },
  clearFilters: {
    gridArea: 'clear',
    fontWeight: 'normal',
  },
  filterButton: {
    gridArea: 'filter',
  },
}));

export const AttendeeFilters = () => {
  const { classes } = useStyles();
  const { t } = useTranslation(['global', 'filters']);
  const { locale, eventUri } = useParams();
  const navigate = useNavigate();
  const { attendeeFiltersSchema } = formSchemas;
  const { currentEvent, eventSettings } = usePrefetchEvent();
  const { startDate, endDate } = currentEvent || {};

  const { attendeeFilters, setAttendeeFilters } = useOutletContext();

  const {
    firstName,
    lastName,
    email,
    attendeeTypeId,
    createdAtLowerBound,
    createdAtUpperBound,
  } = attendeeFilters || {};

  const { handleSubmit, watch, reset, setValue, register } = useCustomForm(
    attendeeFiltersSchema
  );

  const attendeeTypesQuery = useQuery({
    queryKey: ['ticketTypes', { eventUri }],
    refetchOnMount: false,
    select: {
      ticketTypes: ['attendeeTypes', 'all-object'],
    },
  });

  const { ticketTypes: attendeeTypes } = attendeeTypesQuery.data || {};

  const ticketTypeValue = watch('attendeeTypeId', attendeeTypeId) || '';
  const dateFrom = watch('createdAtLowerBound', createdAtLowerBound) || '';
  const dateTo = watch('createdAtUpperBound', createdAtUpperBound) || '';

  const eventDefaultLanguage = eventSettings?.availableLanguages?.default;
  const filterableTicketTypes = Object.values(attendeeTypes || {}).map(
    (ticketType) => {
      const { name, id } = ticketType;
      const ticketName = name?.[locale] || name?.[eventDefaultLanguage];
      return { label: ticketName, value: id };
    }
  );
  const filterTicketName = attendeeTypes?.[ticketTypeValue]?.name?.[locale];
  useEffect(() => {
    setValue('attendeeTypeId', attendeeTypeId);
    setValue('createdAtLowerBound', createdAtLowerBound);
    setValue('createdAtUpperBound', createdAtUpperBound);
  }, [setValue]);

  const onSubmit = (data) => {
    setAttendeeFilters({
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      attendeeTypeId: data?.attendeeTypeId,
      createdAtLowerBound: dateFrom,
      createdAtUpperBound: dateTo,
      'page[number]': '1',
    });
    navigate('..');
  };

  const clearFiltersList = () => {
    reset({
      firstName: '',
      lastName: '',
      email: '',
      attendeeTypeId: '',
      createdAtLowerBound: '',
      createdAtUpperBound: '',
    });
    setAttendeeFilters({});
  };

  const clearKeywordFilters = () => {
    setValue('firstName', '');
    setValue('lastName', '');
    setValue('email', '');
    setAttendeeFilters((prev) => ({
      ...prev,
      firstName: '',
      lastName: '',
      email: '',
    }));
  };

  const clearDateFilters = () => {
    setValue('createdAtLowerBound', '');
    setValue('createdAtUpperBound', '');
    setAttendeeFilters((prev) => ({
      ...prev,
      createdAtLowerBound: '',
      createdAtUpperBound: '',
    }));
  };

  const keywordFilters = (
    <>
      <div className={classes.firstName}>
        <Input
          id="firstName"
          label={t('filters:firstName')}
          placeholder="Kim"
          defaultValue={firstName}
          {...register('firstName')}
        />
      </div>
      <div className={classes.lastName}>
        <Input
          id="lastName"
          label={t('filters:lastName')}
          placeholder="Lee"
          defaultValue={lastName}
          {...register('lastName')}
        />
      </div>
      <div className={classes.email}>
        <Input
          id="email"
          label={t('filters:email')}
          placeholder="example@eventtia.com"
          defaultValue={email}
          {...register('email')}
        />
      </div>
    </>
  );

  const ticketFilters = (
    <SelectInput
      id="attendeeTypeId"
      className={classes.ticketContent}
      defaultValue={filterTicketName || ''}
      options={filterableTicketTypes}
      emptyValueLabel={t('filters:noTicketSelected')}
      {...register('attendeeTypeId')}
    />
  );

  const dateFilters = (
    <>
      <div className={classes.dateFrom}>
        <DateInput
          id="createdAtLowerBound"
          label={t('dates.from')}
          value={dateFrom}
          handleChange={(date) => setValue('createdAtLowerBound', date)}
          timePicker
          minDate={startDate}
          maxDate={endDate}
        />
      </div>
      <div className={classes.dateTo}>
        <DateInput
          id="createdAtUpperBound"
          label={t('dates.to')}
          handleChange={(date) => setValue('createdAtUpperBound', date)}
          value={dateTo}
          timePicker
          minDate={startDate}
          maxDate={endDate}
        />
      </div>
    </>
  );

  return (
    <Drawer
      open
      paperClassName={classes.drawer}
      anchor="right"
      handleClose={() => navigate('..')}
    >
      {() => (
        <form
          aria-label={t('fitlers:ariaLabel.attendeeFilter')}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classes.drawerContent}>
            <Typography variant="h1" className={classes.title}>
              {t('filters:title')}
            </Typography>
            <Divider className={classes.divider} />
            <div className={classes.templateContent}>
              <div className={classes.keywordSearch}>
                <Typography variant="h3" className={classes.keywordTitle}>
                  {t('filters:keywordTitle')}
                </Typography>
                {keywordFilters}
                <Button
                  small
                  variant="tertiary"
                  className={classes.clear}
                  onClick={clearKeywordFilters}
                >
                  {t('filters:clearSearch')}
                </Button>
              </div>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.ticketSearch}>
              <Typography variant="h3" className={classes.ticketTitle}>
                {t('filters:ticketTitle')}
              </Typography>
              {ticketFilters}
            </div>
            <Divider className={classes.divider} />
            <div className={classes.dateSearch}>
              <Typography variant="h3" className={classes.dateTitle}>
                {t('filters:dateTitle')}
              </Typography>
              {dateFilters}
              <Button
                small
                variant="tertiary"
                className={classes.clearDate}
                onClick={clearDateFilters}
              >
                {t('filters:clearDates')}
              </Button>
            </div>
            <div className={classes.filterActionButtons}>
              <Button
                variant="tertiary"
                className={classes.clearFilters}
                onClick={clearFiltersList}
              >
                {t('filters:clearFilters')}
              </Button>
              <Button
                variant="primary"
                className={classes.filterButton}
                type="submit"
              >
                {t('filters:filter')}
              </Button>
            </div>
          </div>
        </form>
      )}
    </Drawer>
  );
};

export default AttendeeFilters;
