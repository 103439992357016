export default {
  en: {
    actions: {
      choose: 'Choose plan',
    },
    title: {
      plans: 'Simple plans for every type of event',
    },
    userPlans: {
      plans: 'Plans',
      helperText:
        "Don't know what plan to choose? Don't worry, we can help you pick the best one according to your needs and budget, send us an email to: <1>sales@eventtia.com</1>",
      plansDescription:
        'Before you can start using Eventtia, you will receive an email to verify your account.',
      plansDescription2:
        'As soon as you complete your verification you can log in and begin planning your event!',
      emailSubject: "I'm interested in an Enterprise plan",
      month: 'Monthly',
      yearly: 'Yearly',
      year: 'Year',
      USD: 'USD(United States Dollar)',
      descriptionCard: 'Here you can red a short description about the plan.',
    },
    paymentForm: {
      cardInformation: 'Card information',
    },
    form: {
      email: 'Email',
      card: 'Credit card number',
      cardName: 'Name of card',
      country: 'Country',
      zipCode: 'ZipCode',
    },
    formPlaceHolder: {
      email: 'Ex: hello@eventtia',
      card: 'Ex: 1234 4567 8901 2345',
      cardName: 'Name',
      date: 'XX/XX',
      cvc: 'XXX',
      Country: 'United States',
      zipCode: 'Name',
    },
  },
  es: {},
  fr: {},
};
