export default {
  en: {
    title: 'Ticket types',
    helperCard:
      'A general ticket is created for every event. You can edit this ticket to fit your needs or you can delete it and <1>create a new one.</1>',
    helperCardAlt: 'Ticket illustration',
    tabs: {
      info: 'General Information',
      registration: 'Registration Form',
      emails: 'Emails',
      advanced: 'Advanced Options',
    },
    actions: {
      createTicket: 'Create ticket',
      copyTicket: 'Copy ticket',
      deleteTicket: 'Delete ticket',
      createAnotherTicket: 'Create another ticket',
      editTicket: 'Edit ticket',
      previewForm: 'Preview form',
      manageFields: 'Manage fields',
      newField: 'New field',
      createAnotherField: 'Create another field',
      editField: 'Edit field',
      addNewRate: 'Add new rate',
    },
    editTicket: {
      title: 'Edit ticket',
      generalInformation: 'General information',
      registration: 'Registration',
      advancedOptions: 'Advanced options',
      paymentOptions: 'Payment options settings',
      createEmail: 'Create email',
    },
    messages: {
      successTickedCreatedTitle: 'You created a new ticket!',
      deleteTicektConfirmation:
        'Are you sure you want to delete the {{ticketName}} ticket type?',
      registrationDescription:
        'Add fields you want to include in the registration from by clicking on “Add field” and you can rearrange them by dragging and dropping the field where you want it to be located. There are some fields are required and cannot be edited or deleted.',
      deleteFieldConfirmation:
        "This action <1>cannot be undone</1>, by deleting <3>{{fieldName}}</3> all related attendees' answers will also be removed.",
    },
    forms: {
      searchFieldName: 'Search field name',
      ticketName: 'Ticket name',
      ticketNamePlaceholder: 'Ex: VIP',
      availableTickets: 'Available tickets',
      noLimit: 'No limit',
      ticketDescription: 'Ticket description',
      ticketDescriptionPlaceholder: 'Type here a description',
      price: 'Price',
      pricePlaceholder: 'Ex: 100,000',
      successfulRegistrationEmail: 'Successful registration email',
      successfulRegistrationTooltip:
        'This email will be sent when an attendee’s registration is completed successfully.',
      organizerApproval: 'Organizer approval',
      organizerApprovalDescr:
        'Select this option if you want to validate a participant before receiving their email confirmation.',
      publicRegistration: 'Public registration',
      publicRegistrationDescr:
        'Select this option for different participant types you want to be registered online or manually. This can be useful for VIPs or the press, for example.',
      registrationWithoutPayment: 'Registration without payment',
      registrationwithoutPaymentDescr:
        'This option allows participants to complete their registration and pay offline via bank transfer, cheque or another method at a later date. These payments can then be entered manually into Eventtia.',
      dynamicRates: 'Dynamic rates',
      dynamicRatesDesc: 'Lorem lorem lorem mmmelore',
      name: 'Name',
      options: 'Options',
      validationType: 'Validation type',
      maximumCharacters: 'Maximum characters',
      allowedValues: 'Allowed values',
      allowedValuesPlaceholder:
        'The options must be comma separated. For Example: Option1,Option2,Option3...',
      display: 'Display',
      filterable: 'Filterable',
      availablePublicListings: 'Available in public listings',
      required: 'Required',
      showInPublicRegistration: 'Show in public registration',
      controlType: 'Control type',
      termsMode: 'Terms mode',
      from: 'From',
      to: 'To',
      fieldOptions: 'Select the options you want to configure for this field',
      selectFieldType: 'Select field type',
      selectValidationType: 'Select validation type',
      selectControlType: 'Select control type',
      selectTermsMode: 'Select terms mode',
      labels: {
        fieldName: 'Field name',
        type: 'Field type',
        text: 'Text',
        textArea: 'Text area',
        label: 'Label',
        singleSelection: 'Single selection',
        multipleSelection: 'Multiple selection',
        dateField: 'Date field',
        includeTime: 'Include time',
        city: 'City',
        image: 'Image',
        file: 'File',
        terms: 'Terms',
        empty: 'Empty',
        letters: 'Letters',
        numbers: 'Numbers',
        telephone: 'Telepone',
        radio: 'Radio',
        dropdown: 'Dropdown list',
        checkBoxes: 'Check boxes',
        url: 'URL',
      },
    },
    fieldsLibrary: {
      title: 'Fields library',
      description:
        'Create your question bank to use it faster while building forms.',
      createField: 'Create a field',
      editField: 'Edit field',
      fieldCreated: 'You created a new field in the library!',
      fieldCreatedDescription:
        'To define in which forms this field will appear, you can go to the Registration tab in each ticket type.',
    },
    pricingTiers: {
      labels: {
        price: 'Price',
        currentPrice: 'Current price',
        startDate: 'Start date',
        endDate: 'End date',
      },
      table: {
        name: 'Name',
        type: 'Field type',
        usedIn: 'Used in',
      },
    },
  },
  es: {},
  fr: {},
  de: {},
};
