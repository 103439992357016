import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import SelectInput from 'eventtia-ui-components/lib/SelectInput';

const RHFSelect = ({ name, ...other }) => {
  const { control } = useFormContext();
  const { multiple } = other;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { ref, value, onChange, ...field },
        fieldState: { error },
      }) => {
        return (
          <SelectInput
            {...field}
            inputRef={ref}
            id={name}
            value={value || (multiple ? [] : '')}
            handleChange={onChange}
            error={error?.message}
            {...other}
          />
        );
      }}
    />
  );
};

RHFSelect.propTypes = {
  name: PropTypes.string.isRequired,
};

export default RHFSelect;
