import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import ImageInput from 'eventtia-ui-components/lib/ImageInput';

const RHFImageInput = ({ name, sizeLimit, ...other }) => {
  const { t } = useTranslation('global');
  const { control, setValue, setError } = useFormContext();

  const checkSizeAndType = (file) => {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    if (!file.length) setValue(name, []);
    else {
      const selectedFileType = file[0].type;
      const size = (file[0].size / 1024).toFixed(0);

      if (size <= sizeLimit && allowedTypes.includes(selectedFileType))
        setValue(name, file);
      else {
        setValue(name, []);
        if (size > sizeLimit)
          setError(name, {
            type: 'manual',
            message: t('formError.invalidSize', { sizeLimit: '5' }),
          });
        else
          setError(name, {
            type: 'manual',
            message: t('formError.invalidFormat'),
          });
      }
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { ref, onChange, ...field },
        fieldState: { error },
      }) => (
        <ImageInput
          {...field}
          id={name}
          handleChange={checkSizeAndType}
          error={error?.message}
          {...other}
        />
      )}
    />
  );
};

RHFImageInput.propTypes = {
  name: PropTypes.string.isRequired,
  sizeLimit: PropTypes.number,
};

RHFImageInput.defaultProps = {
  sizeLimit: 5000,
};

export default RHFImageInput;
