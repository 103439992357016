import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { useParams } from 'react-router-dom';
import pageProperties from './pageProperties';
import moment from '../helpers/dates';

import HeaderB from './blocks/HeaderB';
import BannerB from './blocks/BannerB';
import RegisterB from './blocks/RegisterB';
import DescriptionB from './blocks/DescriptionB';
import DateB from './blocks/DateB';
import LocationB from './blocks/LocationB';
import SponsorsB from './blocks/SponsorsB';
import OrganizerB from './blocks/OrganizerB';
import FooterB from './blocks/FooterB';

const useStyles = makeStyles()((theme) => ({
  template: {
    overflowX: 'hidden',
  },
  root: {
    marginTop: theme.spacing(7.75),
    padding: theme.spacing(7.75, 14, 9.5, 14),
    display: 'grid',
    gridTemplateRows: 'repeat(5, auto)',
    gridTemplateColumns: '58% 38%',
    columnGap: '32px',
    rowGap: '32px',
    gridTemplateAreas: `
      'banner register'
      'description date'
      'description location'
      'sponsors sponsors'
      'organizer organizer'
    `,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4),
      gridTemplateColumns: '100%',
      gridTemplateRows: 'repeat(7, auto)',
      gridTemplateAreas: `
      'banner'
      'register'
      'description'
      'date'
      'location'
      'sponsors'
      'organizer'
    `,
    },
  },
  banner: {
    gridArea: 'banner',
  },
  register: {
    gridArea: 'register',
  },
  description: {
    gridArea: 'description',
  },
  date: {
    gridArea: 'date',
  },
  location: {
    gridArea: 'location',
  },
  sponsors: {
    gridArea: 'sponsors',
  },
  organizer: {
    gridArea: 'organizer',
  },
}));

export const Composer = () => {
  const { classes } = useStyles();
  const { locale } = useParams();
  const logo = pageProperties?.headerLogoUrl;
  const eventBanner = pageProperties?.bannerUrl;
  const primaryColor = pageProperties?.colors?.primary;
  const secondaryColor = pageProperties?.colors?.secondary;
  const data = pageProperties?.metadata;
  const description = pageProperties?.description;
  const eventName = pageProperties?.eventName?.[locale];
  const eventType = pageProperties?.metadata?.eventType;
  const eventLocation = pageProperties?.metadata?.location;
  const eventMap = pageProperties?.metadata?.map;
  const eventStartDate = moment(pageProperties?.dates?.eventStartDate).format(
    'MMM D, h:mm a'
  );
  const eventEndDate = moment(pageProperties?.dates?.eventEndDate).format(
    'MMM D, h:mm a'
  );
  const eventTimezone = moment(pageProperties?.dates?.eventStartDate).format(
    'z'
  );
  const sponsors = pageProperties?.sponsors;
  const organizerData = pageProperties?.footer;

  useEffect(() => {
    localStorage.setItem('evt_bg_lang', locale);
  }, [locale]);

  return (
    <div className={classes.template}>
      <HeaderB logo={logo} />
      <div className={classes.root}>
        <div className={classes.banner}>
          <BannerB banner={eventBanner} />
        </div>
        <div className={classes.register}>
          <RegisterB data={data} eventName={eventName} color={secondaryColor} />
        </div>
        <div className={classes.description}>
          <DescriptionB data={description} />
        </div>
        <div className={classes.date}>
          <DateB
            startDate={eventStartDate}
            endDate={eventEndDate}
            timezone={eventTimezone}
            color={primaryColor}
          />
        </div>
        <div className={classes.location}>
          <LocationB
            type={eventType}
            color={secondaryColor}
            location={eventLocation}
            map={eventMap}
          />
        </div>
        <div className={classes.sponsors}>
          <SponsorsB sponsors={sponsors} />
        </div>
        <div className={classes.organizer}>
          <OrganizerB organizer={organizerData} />
        </div>
      </div>
      <FooterB color={primaryColor} />
    </div>
  );
};

// Composer.propTypes = {
//   pageProperties: PropTypes.arrayOf(PropTypes.string),
// };

// Composer.defaultProps = {
//   pageProperties: {},
// };

export default connect()(Composer);
