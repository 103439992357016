export const LOCALE_PATH = ':locale'; // TODO: validate languages
export const prependLocalePath = (path) => `/${LOCALE_PATH}/${path}`;

const getDisplayName = (paramName, params, entities) => {
  switch (paramName) {
    case 'eventUri': {
      const { eventUri } = params;
      const { events } = entities;
      const event = Object.values(events || {}).find(
        ({ eventUri: uri }) => uri === eventUri
      );
      return event?.name;
    }
    case 'attendeeId': {
      const { attendeeId } = params;
      const attendee = entities.attendees?.[attendeeId];
      return `${attendee?.firstName ?? ''} ${attendee?.lastName ?? ''}`.trim();
    }
    case 'userId': {
      const { userId } = params;
      const user = entities.users?.[userId];
      return `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim();
    }
    case 'ticketId': {
      const { ticketId, locale, eventUri } = params;
      const { events, eventSettings, attendeeTypes } = entities;

      if (!attendeeTypes || !events) return '';

      const currenEvent = Object.values(events).find(
        ({ eventUri: uri }) => uri === eventUri
      );
      const currenEventSettings = Object.values(eventSettings).find(
        ({ event: { id } }) => id === currenEvent.id
      );
      const currentAttendeeType = attendeeTypes?.[ticketId];
      const { default: defaultLanguage } =
        currenEventSettings.availableLanguages;

      return (
        currentAttendeeType?.name?.[locale] ||
        currentAttendeeType?.name?.[defaultLanguage]
      );
    }
    default:
      return '';
  }
};

export const generateBreadcrumbs = (path, params, entities) => {
  const pathComponents = path
    .replace(/\/\*?$/, '')
    .split('/')
    .slice(2);

  const pathTree = [];
  pathComponents.forEach((component) => {
    let previousId = pathTree[pathTree.length - 1]?.tKey ?? '';
    const previousUrl =
      pathTree[pathTree.length - 1]?.url ?? `/${params.locale}`;
    if (previousId.endsWith('.show'))
      previousId = previousId.substring(0, previousId.length - 5);

    const isParam = component.startsWith(':');
    const paramName = component.substring(1);
    if (isParam)
      pathTree.push({
        tKey: `${previousId}.show`,
        name: getDisplayName(paramName, params, entities) || '...',
        url: `${previousUrl}/${params[paramName]}`,
      });
    else
      pathTree.push({
        tKey: previousId === '' ? component : `${previousId}.${component}`,
        url: `${previousUrl}/${component}`,
      });
  });

  return pathTree;
};

export const injectParams = (path, params) =>
  path.replace(/:([^/]+)/g, (paramExpr) => {
    const paramName = paramExpr.replace(':', '').replace(/\(.+/, '');
    return params[paramName];
  });
