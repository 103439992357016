import React from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import backgroundImage from '../../images/loggedOutBackground.png';

import SitewideAnnouncement from '../../components/SitewideAnnouncement';

const currentYear = new Date().getFullYear();

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    minHeight: '100%',
    right: 0,
    left: 0,
    background: `url(${backgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  main: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    position: 'relative',
  },
  footer: {
    padding: `${theme.spacing(3)} 8%`,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    bottom: 0,
    zIndex: theme.zIndex.appBar,
    color: theme.palette.secondary.light,
    '& > span': {
      flex: 1,
    },
  },
  icons: {
    display: 'flex',
    color: theme.palette.secondary.light,
    '& > a': {
      marginRight: theme.spacing(2.5),
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      color: theme.palette.secondary.light,
      '&:last-child': {
        margin: 0,
      },
      '& > svg': {
        fontSize: theme.spacing(2.5),
        color: theme.palette.secondary.light,
      },
    },
  },
}));

const LoggedOutLayout = ({ children }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <SitewideAnnouncement />
      <main className={classes.main}>{children || <Outlet />}</main>

      <footer className={classes.footer}>
        <Typography variant="option">
          Copyright &#169; {currentYear} Eventtia
        </Typography>

        <div className={classes.icons}>
          <a
            href="https://facebook.com/eventtia.ems/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>

          <a
            href="https://twitter.com/eventtia"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </a>

          <a
            href="https://www.youtube.com/channel/UCwNGAbT1RTHYBgZ5IizrWeg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YouTubeIcon />
          </a>

          <a
            href="https://www.linkedin.com/company/eventtia/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default LoggedOutLayout;
