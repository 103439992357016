import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import EmailIcon from '@mui/icons-material/Email';
import PublicIcon from '@mui/icons-material/Public';

const useStyles = makeStyles()((theme) => ({
  divider: {
    marginBottom: theme.spacing(4),
  },
  organizerInfo: {
    display: 'grid',
    gridTemplateRows: 'repeat(3, auto)',
    rowGap: '20px',
    gridTemplateAreas: `
      'organizerData'
      'organizerDescription'
      'organizerContact'
    `,
  },
  organizerData: {
    display: 'flex',
    flexDirection: 'row',
    gridArea: 'organizerData',
  },
  organizerName: {
    fontWeight: 'bold',
    margin: theme.spacing(0, 2.5),
  },
  organizerDescription: {
    gridArea: 'organizerDescription',
    marginBottom: theme.spacing(0),
  },
  organizerContact: {
    display: 'grid',
    gridArea: 'organizerContact',
    gridTemplateRows: '20px 12px auto',
    gridTemplateColumns: '20px 12px auto',
    gridTemplateAreas: `
      'emailIcon . email'
      '. . .'
      'websiteIcon . website'
    `,
  },
  email: {
    gridArea: 'email',
  },
  emailIcon: {
    gridArea: 'emailIcon',
    placeSelf: 'center',
  },
  website: {
    gridArea: 'website',
    marginBottom: '0',
  },
  websiteIcon: {
    gridArea: 'websiteIcon',
    placeSelf: 'center',
  },
}));

const OrganizerB = ({ organizer }) => {
  const { classes } = useStyles();
  const { locale } = useParams();
  const name = organizer?.organizerName;
  const { email, website } = organizer?.organizerInfo || {};

  if (organizer)
    return (
      <>
        <Divider className={classes.divider} />
        <div className={classes.organizerInfo}>
          <div className={classes.organizerData}>
            <img src={organizer?.organizerLogo} width="64px" alt="" />
            <Typography variant="h4" className={classes.organizerName}>
              {name}
            </Typography>
          </div>
          <Typography variant="h4" className={classes.organizerDescription}>
            {organizer?.organizerDescription?.[locale]}
          </Typography>
          <div className={classes.organizerContact}>
            <EmailIcon className={classes.emailIcon} />
            <Typography variant="h4" className={classes.email}>
              {email}
            </Typography>
            <PublicIcon className={classes.websiteIcon} />
            <Typography variant="h4" className={classes.website}>
              {website}
            </Typography>
          </div>
        </div>
      </>
    );
  return null;
};

OrganizerB.propTypes = {
  organizer: PropTypes.shape({
    organizerDescription: PropTypes.objectOf(PropTypes.string),
    organizerInfo: PropTypes.shape({
      email: PropTypes.string,
      website: PropTypes.string,
    }),
    organizerLogo: PropTypes.string,
    organizerName: PropTypes.string,
  }),
};

OrganizerB.defaultProps = {
  organizer: {},
};

export default OrganizerB;
