export default {
  en: {
    messages: {
      forgotPasswordThanksTitle: 'Thank you!',
      forgotPasswordDescription:
        'You will receive an email to recover your password.',
    },
  },
  es: {},
  fr: {},
};
