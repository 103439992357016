import React from 'react';
import Loader from 'eventtia-ui-components/lib/Loader';
import LoggedOutLayout from '../layouts/LoggedOutLayout';
import Login from '../pages/Login';
import useAuthContext from '../hooks/useAuthContext';

const AuthGuard = ({ children }) => {
  const { isInitialized, isAuthenticated } = useAuthContext();

  if (!isInitialized) return <Loader loading variant="absolute" />;

  if (!isAuthenticated)
    return (
      <LoggedOutLayout>
        <Login />
      </LoggedOutLayout>
    );

  return children;
};

export default AuthGuard;
