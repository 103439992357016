import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import FileInput from 'eventtia-ui-components/lib/FileInput';

const RHFFileInput = ({ name, sizeLimit, ...other }) => {
  const { t } = useTranslation('global');
  const { control, setValue, setError } = useFormContext();

  const checkSize = (file) => {
    if (!file.length) setValue(name, []);
    else {
      const size = (file[0].size / 1024).toFixed(0);
      if (size <= sizeLimit) setValue(name, file);
      else {
        setValue(name, []);
        setError(name, {
          type: 'manual',
          message: t('formError.invalidSize', { sizeLimit }),
        });
      }
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { ref, onChange, ...field },
        fieldState: { error },
      }) => (
        <FileInput
          {...field}
          id={name}
          handleChange={checkSize}
          error={error?.message}
          {...other}
        />
      )}
    />
  );
};

RHFFileInput.propTypes = {
  name: PropTypes.string.isRequired,
  sizeLimit: PropTypes.number,
};

RHFFileInput.defaultProps = {
  sizeLimit: 5000,
};

export default RHFFileInput;
