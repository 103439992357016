import { combineReducers } from '@reduxjs/toolkit';
import { fetchConfig } from '../../actions/callApi';
import { SET_FILTERS, CLEAR_FILTERS } from '../../actions/filters';

export const createReducer =
  (entity) =>
  // eslint-disable-next-line default-param-last
  (state = {}, action) => {
    if (action.entity !== entity) return state;
    const { type, filters } = action;
    const { filters: filterConfig = {} } = fetchConfig[entity];
    switch (type) {
      case SET_FILTERS:
        return {
          ...state,
          ...filters,
        };
      case CLEAR_FILTERS: {
        const newState = {};
        Object.keys(state).forEach((filter) => {
          if (filterConfig[filter]?.clearable === false)
            newState[filter] = state[filter];
        });
        return newState;
      }
      default:
        return state;
    }
  };

const filtersReducer = {};
Object.keys(fetchConfig).forEach((entity) => {
  filtersReducer[entity] = createReducer(entity);
});

export default combineReducers(filtersReducer);
