import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import SingleCheckbox from 'eventtia-ui-components/lib/SingleCheckbox';

const RHFSingleCheckbox = ({ name, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, value, ...field }, fieldState: { error } }) => (
        <SingleCheckbox
          {...field}
          inputRef={ref}
          id={name}
          checked={value}
          error={error?.message}
          {...other}
        />
      )}
    />
  );
};

RHFSingleCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
};

export default RHFSingleCheckbox;
