import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider } from 'react-hook-form';

const RHForm = ({ rhfMethods, onSubmit, ...formProps }) => {
  const { handleSubmit } = rhfMethods;

  return (
    <FormProvider {...rhfMethods}>
      <form {...formProps} onSubmit={handleSubmit(onSubmit)} />
    </FormProvider>
  );
};

RHForm.propTypes = {
  rhfMethods: PropTypes.shape({
    watch: PropTypes.func,
    handleSubmit: PropTypes.func,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RHForm;
