export default {
  en: {
    title: 'Attendee',
    title_plural: 'Attendees',
    categories: {
      all: 'All',
      registered: 'Registered',
      checkedIn: 'Checked in',
      deleted: 'Deleted',
    },
    ariaLabel: {
      createAttendee: 'Create Attendee',
      editAttendee: 'Edit Attendee',
    },
    actions: {
      addTag: 'Add tag',
      addTransaction: 'Add transaction',
      checkIn: 'Check in',
      createAttendee: 'Create attendee',
      createList: 'Create list',
      createOtherAttendee: 'Create other attendee',
      createQR: 'create QR code',
      deleteAttendee: 'Delete attendee',
      download: 'Download',
      editAttendee: 'Edit attendee',
      exportAll: 'Export all to excel',
      filterBy: 'Filter by:',
      generateAppAccessToken: 'Generate app access token',
      insertCoupon: 'Insert coupon',
      printAll: 'Print all badges',
      printBadge: 'Print badge',
      registrationLink: 'Registration Link',
    },
    sections: {
      info: 'General Information',
      email: 'Emails',
      communications: 'Communications',
      activities: 'Activities',
      payments: 'Payments',
      subtitles: {
        attendeePayments: 'Attendee payments',
      },
      networking: 'Networking',
    },
    fields: {
      attendeeType: 'Ticket type',
      avatar: '',
      company: 'Company',
      createdAt: 'Date created',
      email: 'Email',
      firstName: 'First name',
      lastName: 'Last name',
      name: 'Name',
      status: 'Status',
      tags: 'Tags',
      telephone: 'Telephone',
    },
    status: {
      checkedIn: 'Checked in',
      confirmed: 'Confirmed',
      deleted: 'Deleted',
      paid: 'Paid',
      registered: 'Registered',
      approve: 'Approve registration',
      reject: 'Reject registration',
      change: 'Change status',
    },
    payments: {
      status: {
        approved: 'Approved',
        expired: 'Expired',
        rejected: 'Rejected',
      },
      type: {
        deposit: 'Deposit',
      },
      summary: {
        subtotal: 'Subtotal',
        discount: 'Discount',
        vat: 'VAT',
        total: 'Total',
        balanceInFavor: 'Balance in favor',
      },
    },
    messages: {
      successCreatedAttendee: 'Success attendee Created',
      deleteAttendeeConfirmation:
        "Are you sure you want to delete this attendee? This action can't be undone.",
      errors: {
        creatingAttendee: 'Error creating attendee',
        creatingAtendeeDescription: 'There was an issue creating the Attendee',
      },
      changeStatus: '???',
      emptyState:
        "We're waiting for the first attendee to register, in the meantime you can upload your attendees manually.",
      createdByAt: 'Created at {{createdAt}}',
      info: {
        successAttendeeCreatedTitle: 'You have created a new attendee!',
        successAttendeeCreatedDescription:
          'It doesn’t stop here. Click below to continue creating more attendees or to edit their details.',
      },
    },
    forms: {
      selectAttendeeType: 'Select a Attendee Type',
      ticketType: 'Ticket type',
      notRegisteredLink: 'Register here',
      optional: 'optional',
      signature: 'Signature',
      firstName: 'First name',
      name: 'Name',
      lastName: 'Last name',
      telephone: 'Phone',
      phone: 'Phone',
      country: 'Country',
      jobTitle: 'Job title',
      company: 'Company',
      region: 'Region',
      city: 'City',
      photo: 'Photo',
      searchBy: 'Keywords',
      email: 'Email',
      accessToken: 'Access token',
      validateToken: 'Validate token',
      logIn: 'Log in',
      error: 'Error',
      createTicket: 'Create ticket type',
      createCategory: 'Create category',
      suggestedDimensions:
        'We suggest using an image with the following dimensions: 1024x1024px.',
    },
    formsPlaceHolder: {
      phone: '+ 1 123 456 7897',
    },
    reports: {
      attendees: 'Attendees',
      totalAttendees: 'Total attendees',
      registration: 'Registration',
      attended: 'Attended',
      cancelled: 'Cancelled',
      messages: {
        ordersCreated: 'Orders created',
        inviteDescription: 'Invited attendees',
        purchasedTickets: 'Purchased tickets',
        attendeesDescription: 'Registered attendees',
        ticketsDescription: 'Ticket sales',
        websiteDescription: 'Website Visits',
        noInviteTitle: 'No invitation yet',
        noAttendeesTitle: 'No attendees yet',
        noTicketsTitle: '$ 0',
        noWebsiteTitle: 'No website yet',
        noPurchasedTickets: 'No tickets purchased yet',
        noOrdersCreated: 'No orders created yet',
      },
    },
  },
  es: {
    title: 'Asistente',
    title_plural: 'Asistentes',
    categories: {
      all: 'Todos',
      registered: 'Registrados',
      checkedIn: 'Asistieron',
      deleted: 'Eliminados',
    },
  },
  fr: {},
  de: {},
};
