import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RadioButtons from 'eventtia-ui-components/lib/RadioButtons';

const EventDateFormatSelector = ({ id, value, handleChange }) => {
  const { t } = useTranslation(['events']);

  const dateFormatOptions = [
    { label: t('forms.ddMmYyyy'), value: 'dd/mm/yyyy' },
    { label: t('forms.mMDdYyyy'), value: 'mm/dd/yyyy' },
  ];

  return (
    <RadioButtons
      row
      id={id}
      value={value}
      options={dateFormatOptions}
      handleChange={handleChange}
      label={t('forms.dateFormat')}
    />
  );
};

EventDateFormatSelector.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default EventDateFormatSelector;
