import React from 'react';
import { RouterProvider } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import moment from 'moment-timezone';
import Loader from 'eventtia-ui-components/lib/Loader';
// import { loggedIn } from '../helpers/auth';
import useAuthContext from '../hooks/useAuthContext';
import { router } from '../routes/router';
// import i18n, { availableLanguages } from '../locales';

// import { loggedOutRoutesConfig, loggedInRoutesConfig } from '../routes/config';

const App = () => {
  // const { pathname } = useLocation();
  // const navigate = useNavigate();
  // const { locale } = useParams();
  const { isInitialized } = useAuthContext();
  // const appRoutes = isLoggedIn ? loggedInRoutesConfig : loggedOutRoutesConfig;

  // useEffect(() => {
  //   if (availableLanguages.includes(locale)) {
  //     moment.locale(locale);
  //     i18n.changeLanguage(locale);
  //   } else window.location.href = '/en';
  // }, [locale]);

  // useEffect(() => {
  //   if (loggedIn()) {
  //     dispatchCallApi('currentUser');
  //     dispatchCallApi('accountDetails');
  //   }
  // }, [dispatchCallApi, loggedInSuccessfully, activatedSuccessfully]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  if (!isInitialized) return <Loader loading variant="absolute" />;

  return <RouterProvider router={router} />;
};

export default App;
