import React, { useState, useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Button from 'eventtia-ui-components/lib/Button';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Message from 'eventtia-ui-components/lib/Message';
import useCustomForm from '../../hooks/useCustomForm';
import useAuthContext from '../../hooks/useAuthContext';
import formSchemas from '../../helpers/formSchemas';
import apiKeys from '../../helpers/apiKeys';
import withFormMessage from '../../hocs/withFormMessage';
// import useDidUpdate from '../../hooks/useDidUpdate';

import RHForm from '../rhfWrappers/RHForm';
import { RHFInput, RHFPasswordInput } from '../rhfWrappers';
import fetchData from '../../api/fetchData';

const useStyles = makeStyles()((theme) => ({
  buttonContainer: {
    textAlign: 'center',
  },
  message: {
    marginTop: -theme.spacing(4),
    marginBottom: theme.spacing(2),
    maxWidth: 785,
  },
}));

const LoginForm = () => {
  const { classes } = useStyles();
  const theme = useTheme();

  const { login } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const { locale } = useParams();

  const { t } = useTranslation('login');

  const [errorMessage, setErrorMessage] = useState();
  const [captchaToken, setCaptchaToken] = useState();
  const captchaRef = useRef(null);

  const onErrorCaptcha = () => {
    setCaptchaToken();
    captchaRef?.current.reset();
  };

  const { loginSchema } = formSchemas;

  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  const methods = useCustomForm(loginSchema, { mode: 'onChange' });
  const {
    formState: { isValid },
  } = methods;

  // useDidUpdate(() => {
  //   customErrors.forEach(({ name, type, message }) =>
  //     setError(name, { type, message })
  //   );
  //   setErrorMessage({
  //     type: 'error',
  //     text: customErrors.map(({ message }) => message).join('\n'),
  //   });
  // }, [customErrors, setError]);

  const getTokenMutation = useMutation({
    mutationFn: (data) => fetchData('login', data),
    onSuccess: ({ token }) => {
      login(token);
    },
    onError: (error) =>
      enqueueSnackbar(JSON.stringify(error.message), { variant: 'error' }),
  });

  return (
    <>
      <Message
        className={classes.message}
        type={errorMessage?.type}
        onClose={() => setErrorMessage()}
        message={errorMessage?.text}
      />
      <RHForm
        aria-label={t('ariaLabel')}
        autoComplete="on"
        onSubmit={(data) =>
          getTokenMutation.mutate({ ...data, recaptcha_token: captchaToken })
        }
        rhfMethods={methods}
      >
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <RHFInput name="email" label={t('forms.username')} />
          </Grid>
          <Grid item sm={12}>
            <RHFPasswordInput name="password" label={t('forms.password')} />
          </Grid>
          <Grid item sm={12} align="center">
            <ReCAPTCHA
              ref={captchaRef}
              sitekey={apiKeys.captcha}
              onChange={setCaptchaToken}
              size={isMobile ? 'compact' : 'normal'}
              onExpired={onErrorCaptcha}
              onErrored={onErrorCaptcha}
              hl={locale}
            />
          </Grid>
          <Grid className={classes.buttonContainer} item sm={12}>
            <Button
              type="submit"
              disabled={
                !(isValid && !!captchaToken) || getTokenMutation.isLoading
              }
            >
              {t('forms.signIn')}
            </Button>
          </Grid>
        </Grid>
      </RHForm>
    </>
  );
};

LoginForm.propTypes = {};

LoginForm.defaultProps = {
  enableForm: undefined,
};

export default withFormMessage(LoginForm);
