import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import TagManager from 'react-gtm-module';
import { Provider as ReduxProvider } from 'react-redux';
import { CacheProvider } from '@emotion/react';
import { SnackbarProvider } from 'notistack';
import createCache from '@emotion/cache';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CssBaseline from '@mui/material/CssBaseline';
import reportWebVitals from './reportWebVitals';
import createTheme from './helpers/createTheme';
import initStore from './store/initStore';
import App from './App';
import queryClient from './helpers/queryClient';
import AuthProvider from './components/AuthProvider';

const tagManagerArgs = {
  gtmId: 'GTM-PLJMSTK',
  dataLayerName: 'TowerDataLayer',
};

TagManager.initialize(tagManagerArgs);

const eventtiaProCustomTheme = createTheme({
  fontFamily: 'Uber Move Text, sans-serif',
  secondaryColor: {
    light: '#4DDAC6',
    main: '#3EBDAB',
    dark: '#31A090',
    contrastText: '#FFFFFF',
  },
});

const muiCache = createCache({
  key: 'tower',
  prepend: true,
});

const store = initStore();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={3}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools client={queryClient} />
        <ReduxProvider store={store}>
          <CacheProvider value={muiCache}>
            <ThemeProvider theme={eventtiaProCustomTheme}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DndProvider backend={HTML5Backend}>
                  <CssBaseline />
                  <AuthProvider>
                    <App />
                  </AuthProvider>
                </DndProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </CacheProvider>
        </ReduxProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line
reportWebVitals(console.log);
