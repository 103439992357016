import baseMoment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';

export { baseMoment };

const moment = (date) => baseMoment.parseZone(date).tz('America/Bogota', true);
export default moment;

export const getMonthRange = (date) => {
  const firstMonthDay = moment(date).startOf('month').format();
  const lastMonthDay = moment(date).endOf('month').format();
  return {
    monthUpperBound: lastMonthDay,
    monthLowerBound: firstMonthDay,
  };
};

export const resetDateRangeTimes = (datesRange) => {
  const copyOfDateRanges = cloneDeep(datesRange || {});
  const { startDate, endDate } = copyOfDateRanges;
  if (startDate?.lower)
    startDate.lower = moment(startDate?.lower).startOf('day').format();
  if (startDate?.upper)
    startDate.upper = moment(startDate?.upper).endOf('day').format();
  if (endDate?.upper)
    endDate.upper = moment(endDate?.lower).startOf('day').format();
  if (endDate?.upper)
    endDate.upper = moment(endDate?.upper).endOf('day').format();

  return {
    datesRange: {
      endDate,
      startDate,
    },
  };
};
