import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles()(() => ({
  header: {
    background: '#F6F6FA 0% 0% no-repeat padding-box',
    boxShadow: '0px 2px 6px #736A7329',
    border: '0.6000000238418579px solid #8A808A0F',
    opacity: '1',
    position: 'fixed',
    top: '0',
    width: '100%',
    zIndex: '1500',
  },
}));

const HeaderB = ({ logo }) => {
  const { classes } = useStyles();
  return (
    <Toolbar className={classes.header}>
      <IconButton>{logo && <img src={logo} alt="" />}</IconButton>
    </Toolbar>
  );
};

HeaderB.propTypes = {
  logo: PropTypes.string,
};

HeaderB.defaultProps = {
  logo: '',
};

export default HeaderB;
