import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useQuery from '../hooks/useQuery';
import useRoutes from '../hooks/useRoutes';

const SubscriptionGuard = ({ children }) => {
  const { goTo } = useRoutes();
  const { pathname } = useLocation();
  // pathname without locale
  const parsedPathname = pathname.split('/')[2];

  const subscriptionsQuery = useQuery({
    queryKey: ['activeSubscriptions'],
    staleTime: 5 * 60 * 1000, // 5 minutes in milliseconds
    select: {
      subscription: [
        'accountSubscriptions',
        'main',
        (data) =>
          ['processing', 'canceled'].includes(data.status) ? data : {},
      ],
    },
  });

  const { subscription } = subscriptionsQuery.data || {};
  const { status } = subscription || {};

  useEffect(() => {
    if (status === 'canceled' && parsedPathname !== 'profile')
      goTo('profile/subscription');
  }, [status, parsedPathname, goTo]);

  return children;
};

export default SubscriptionGuard;
