import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

// eslint-disable-next-line default-param-last
export default (state = {}, action) => {
  if (!action?.response?.entities) return state;

  const newEntities = action.response.entities;
  let newState;

  if (action.method === 'DELETE') {
    newState = cloneDeep(state);

    Object.keys(newEntities).forEach((entityType) =>
      Object.keys(newEntities[entityType]).forEach(
        (id) => delete newState[entityType][id]
      )
    );
  } else newState = merge({}, state, newEntities);

  if (typeof action.additionalReduction === 'function')
    newState = action.additionalReduction(newState, action);

  return newState;
};
