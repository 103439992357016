export default {
  en: {
    title: 'Orders',
    order: 'Order',
    description: 'Tickets corresponding to the current order:',
    totalOrders: 'Total orders: {{total}}',
    orderDetails: 'Order details',
    ticketUuid: 'Ticket id: {{ticketUuid}}',
    buyer: 'Buyer: {{memberName}}',
    memberName: 'Buyer',
    totalTickets: 'Total tickets',
    totalAmount: 'Total amount',
    orderCreatedAt:
      'Order # <1>{{orderUuid}}</1> - created at: <5>{{createdAt}}</5>',
    createdAt: 'Created at',
    createdBy: 'Created by',
    status: 'Status',
    attendanceMode: {
      online: 'Virtual',
      offline: 'Physical',
      mixed: 'Hyibrid',
    },
    ticketStatus: {
      attendee: 'Attendee',
      registered: 'Registered',
      pending: 'Pending',
      unassigned: 'Unassigned attendee',
    },
  },
};
