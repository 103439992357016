import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import Button from 'eventtia-ui-components/lib/Button';
import Drawer from 'eventtia-ui-components/lib/Drawer';
import DateInput from 'eventtia-ui-components/lib/DateInput';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useParams, useNavigate } from 'react-router-dom';
import formSchemas from '../../helpers/formSchemas';
import useCustomForm from '../../hooks/useCustomForm';
import { setFilters, clearFilters } from '../../actions/filters';

const useStyles = makeStyles()((theme) => ({
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  drawerContent: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(5, 4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.5, 2, 0),
      margin: theme.spacing(-4),
    },
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    color: theme.palette.darkGrey.light,
  },
  filterActionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'sticky',
    bottom: 0,
    right: 0,
    background: theme.palette.common.white,
    margin: theme.spacing(-4),
    padding: theme.spacing(1.5, 3),
    borderRadius: '0px 0px 5px 5px',
    [theme.breakpoints.down('md')]: {
      display: 'grid',
      gridTemplateAreas: `
      'filter'
      'clear'
      `,
      gridTemplateColumns: '100%',
      rowGap: theme.spacing(1.5),
    },
  },
  clearFilterButton: {
    gridArea: 'clear',
  },
  submitFilterButton: {
    gridArea: 'filter',
  },
  title: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      paddingTop: theme.spacing(2),
    },
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  dates: {
    display: 'grid',
    gridTemplateRows: 'repeat(4, auto)',
    gridTemplateColumns: 'repeat(2, auto)',
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(1.75),
    gridTemplateAreas: `
      'startFrom startTo'
      'clearStartDates .'
      'endFrom endTo'
      'clearEndDates .'
    `,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      gridTemplateRows: 'repeat(6, auto)',
      gridTemplateColumns: 'repeat(1, auto)',
      gridTemplateAreas: `
      'startFrom'
      'startTo'
      'clearStartDates'
      'endFrom'
      'endTo'
      'clearEndDates'
    `,
    },
  },
  startFrom: {
    gridArea: 'startFrom',
  },
  startTo: {
    gridArea: 'startTo',
  },
  endFrom: {
    gridArea: 'endFrom',
  },
  endTo: {
    gridArea: 'endTo',
  },
  clearStartDates: {
    gridArea: 'clearStartDates',
    width: 'fit-content',
    height: 'fit-content',
    paddingLeft: theme.spacing(0),
    marginLeft: theme.spacing(-3.5),
    fontWeight: 'normal',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.light,
      fontWeight: 'lighter',
    },
  },
  clearEndDates: {
    gridArea: 'clearEndDates',
    width: 'fit-content',
    height: 'fit-content',
    paddingLeft: theme.spacing(0),
    marginLeft: theme.spacing(-3.5),
    fontWeight: 'normal',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.light,
      fontWeight: 'lighter',
    },
  },
}));

export const EventFilters = ({
  activeFilters,
  setFilters: dispatchSetFilters,
  clearFilters: dispatchClearFilters,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation('filters');
  const { eventUri } = useParams();
  const navigate = useNavigate();

  const {
    start_date_lower_bound: startDateFrom,
    start_date_upper_bound: startDateTo,
    end_date_lower_bound: endDateFrom,
    end_date_upper_bound: endDateTo,
  } = activeFilters || {};

  const { filterActionsSchema } = formSchemas;
  const { handleSubmit, watch, setValue, reset } = useCustomForm(
    filterActionsSchema,
    { mode: 'onChange', activeFilters }
  );

  useEffect(() => {
    setValue('startLowerBound', startDateFrom);
    setValue('startUpperBound', startDateTo);
    setValue('endLowerBound', endDateFrom);
    setValue('endUpperBound', endDateTo);
  }, [setValue, startDateFrom, startDateTo, endDateFrom, endDateTo]);

  const startFrom = watch('startLowerBound', startDateFrom) || '';
  const startTo = watch('startUpperBound', startDateTo) || '';
  const endFrom = watch('endLowerBound', endDateFrom) || '';
  const endTo = watch('endUpperBound', endDateTo) || '';

  const onSubmit = () => {
    dispatchSetFilters(
      'events',
      {
        ...activeFilters,
        start_date_lower_bound: startFrom,
        start_date_upper_bound: startTo,
        end_date_lower_bound: endFrom,
        end_date_upper_bound: endTo,
      },
      { eventUri }
    );
    navigate('..');
  };

  const clearStartDates = () => {
    setValue('startLowerBound', '');
    setValue('startUpperBound', '');
    dispatchSetFilters(
      'events',
      {
        ...activeFilters,
        start_date_upper_bound: '',
        start_date_lower_bound: '',
      },
      { eventUri }
    );
  };
  const clearEndDates = () => {
    setValue('endLowerBound', '');
    setValue('endUpperBound', '');
    dispatchSetFilters(
      'events',
      {
        ...activeFilters,
        end_date_upper_bound: '',
        end_date_lower_bound: '',
      },
      { eventUri }
    );
  };

  const dateFilters = (
    <div className={classes.dates}>
      <div className={classes.startFrom}>
        <DateInput
          id="startLowerBound"
          label={t('dates.startFrom')}
          value={startFrom}
          handleChange={(date) => setValue('startLowerBound', date)}
          timePicker
        />
      </div>
      <div className={classes.startTo}>
        <DateInput
          id="startUpperBound"
          label={t('dates.startTo')}
          value={startTo}
          handleChange={(date) => setValue('startUpperBound', date)}
          timePicker
        />
      </div>
      <Button
        small
        variant="tertiary"
        className={classes.clearStartDates}
        onClick={clearStartDates}
      >
        {t('filters:clearDates')}
      </Button>
      <div className={classes.endFrom}>
        <DateInput
          id="endLowerBound"
          label={t('dates.endFrom')}
          value={endFrom}
          handleChange={(date) => setValue('endLowerBound', date)}
          timePicker
        />
      </div>
      <div className={classes.endTo}>
        <DateInput
          id="endUpperBound"
          label={t('dates.endTo')}
          value={endTo}
          handleChange={(date) => setValue('endUpperBound', date)}
          timePicker
        />
      </div>
      <Button
        small
        className={classes.clearEndDates}
        variant="tertiary"
        onClick={clearEndDates}
      >
        {t('filters:clearDates')}
      </Button>
    </div>
  );

  const clearFiltersList = () => {
    reset({
      start_date_upper_bound: '',
      start_date_lower_bound: '',
      end_date_upper_bound: '',
      end_date_lower_bound: '',
    });
    dispatchClearFilters('events', { eventUri });
  };

  return (
    <div className={classes.root}>
      <Drawer
        open
        paperClassName={classes.drawer}
        anchor="right"
        handleClose={() => navigate('..')}
      >
        {() => (
          <form
            aria-label={t('ariaLabel.eventFilter')}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={classes.drawerContent}>
              <Typography variant="h1" className={classes.title}>
                {t('filters:title')}
              </Typography>
              <Divider className={classes.divider} />
              {dateFilters}
              <div className={classes.filterActionButtons}>
                <Button
                  variant="tertiary"
                  className={classes.clearFilterButton}
                  onClick={clearFiltersList}
                >
                  {t('filters:clearFilters')}
                </Button>
                <Button
                  variant="primary"
                  className={classes.submitFilterButton}
                  type="submit"
                >
                  {t('filters:filter')}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Drawer>
    </div>
  );
};

EventFilters.propTypes = {
  activeFilters: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  setFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
};

EventFilters.defaultProps = {
  activeFilters: {},
};

const mapStateToProps = ({ filters: { events: activeFilters } }) => ({
  activeFilters,
});

export default connect(mapStateToProps, { setFilters, clearFilters })(
  EventFilters
);
