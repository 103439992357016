import humps from 'humps';

// Dont use the same param name in different identifiers
const endpoints = {
  activateAccount: 'accounts/activate',
  login: 'sessions',
  signUp: 'registrations',
  requestPasswordRecovery: 'passwords',
  activateUserPassword: 'passwords/activate',

  getCommercialPlans: 'commercial_plans',
  updateUser: ({ id }) => `users/${id}`,
  updateUserPassword: () => 'passwords/change',
  activeSubscriptions: 'account_subscriptions',
  getCurrentSubscription: 'account_subscriptions/current',
  stripeOnboarding:
    'accounts/stripe-onboarding?callbacks_urls[success_url]=/en/profile/subscription/onboarding-success&callbacks_urls[cancel_url]=/en/profile/subscription/onboarding-fail',
  managePayment: ({ id }) =>
    `account_subscriptions/${id}/change-payment-method`,
  accountDetails: 'accounts/details',
  destroySubscription: ({ id }) => `account_subscriptions/${id}`,
  createSubscription: 'account_subscriptions',
  accountResources: 'stats/accounts/total-resources',

  currentUser: 'users/retrieve',
  users: 'users',
  user: ({ id }) => `users/${id}`,
  createUser: 'users',
  deleteUser: ({ id }) => `users/${id}`,

  createArea: 'role_categories',
  areas: 'role_categories',
  area: ({ id }) => `role_categories/${id}`,
  userAreas: ({ id }) => `users/${id}/categories`,
  totalUsersInAreas: 'stats/role_categories/total-users',
  totalEventsInAreas: 'stats/role_categories/total-events',
  roles: 'roles',
  createRole: 'roles',
  getRole: ({ roleId }) => `roles/${roleId}`,
  updateRole: ({ roleId }) => `roles/${roleId}`,
  deleteRole: ({ roleId }) => `roles/${roleId}`,
  rolePermissions: ({ roleId }) => `roles/${roleId}/role_permissions`,
  permissions: 'permissions',
  searchUser: ({ search }) => `users?email=${search}`,
  updateArea: ({ id }) => `role_categories/${id}`,
  deleteArea: ({ id }) => `role_categories/${id}`,

  attendee: ({ eventUri, attendeeId }) =>
    `events/${eventUri}/attendees/${attendeeId}?include=attendee_type_custom_fields,attendee_type`,
  getAttendee: ({ eventUri, id }) => `events/${eventUri}/attendees/${id}`,
  updateCurrentAttendee: ({ eventUri, attendeeId }) =>
    `events/${eventUri}/attendees/${attendeeId}`,
  createAttendee: ({ eventUri }) => `events/${eventUri}/attendees`,
  deleteAttendee: ({ eventUri, attendeeId }) =>
    `events/${eventUri}/attendees/${attendeeId}`,
  attendees: ({ eventUri }) => `events/${eventUri}/attendees?order=last_name`,

  createTicketType: ({ eventUri }) => `events/${eventUri}/attendee_types`,
  ticketType: ({ eventUri, ticketId }) =>
    `events/${eventUri}/attendee_types/${ticketId}?include=fields`,
  ticketTypes: ({ eventUri }) =>
    `events/${eventUri}/attendee_types?available_seats=true`,
  updateTicketType: ({ eventUri, ticketId }) =>
    `events/${eventUri}/attendee_types/${ticketId}?include=current_price`,
  deleteTicketType: ({ eventUri, ticketId }) =>
    `events/${eventUri}/attendee_types/${ticketId}`,

  ticketTypeFields: ({ eventUri, ticketId }) =>
    `events/${eventUri}/attendee_type/${ticketId}/fields`,
  ticketTypeEmails: ({ eventUri, ticketId }) =>
    `events/${eventUri}/attendee_types/${ticketId}/entity-notifications`,

  createField: ({ eventUri }) => `events/${eventUri}/fields`,
  fields: ({ eventUri }) => `events/${eventUri}/fields`,
  updateField: ({ eventUri, fieldId }) =>
    `events/${eventUri}/fields/${fieldId}`,
  deleteField: ({ eventUri, fieldId }) =>
    `events/${eventUri}/fields/${fieldId}?reorder_fields=true`,
  reorderFields: ({ eventUri, ticketId }) =>
    `events/${eventUri}/attendee_type/${ticketId}/fields/change-order`,
  orderFields: ({ eventUri }) => `events/${eventUri}/fields/change-order`,
  orders: ({ eventUri }) => `events/${eventUri}/orders`,
  order: ({ eventUri, orderUuid }) => `events/${eventUri}/orders/${orderUuid}`,
  orderTickets: ({ eventUri, orderUuid }) =>
    `events/${eventUri}/orders/${orderUuid}/tickets`,
  associateField: ({ eventUri, attendeeTypeId }) =>
    `events/${eventUri}/attendee_type/${attendeeTypeId}/fields`,
  unassociateField: ({ eventUri, attendeeTypeId, fieldId }) =>
    `events/${eventUri}/attendee_type/${attendeeTypeId}/fields/${fieldId}`,

  pricingTiers: ({ eventUri, ticketId }) =>
    `events/${eventUri}/pricing_tiers?entity_id=${ticketId}&entity_type=AttendeeType`,
  createPricingTier: ({ eventUri }) => `events/${eventUri}/pricing_tiers`,
  updatePricingTier: ({ eventUri, pricingTierId }) =>
    `events/${eventUri}/pricing_tiers/${pricingTierId}`,
  deletePricingTier: ({ eventUri, pricingTierId }) =>
    `events/${eventUri}/pricing_tiers/${pricingTierId}`,

  createEvent: 'events',
  events: 'events',
  currentEvent: ({ eventUri }) => `events/${eventUri}?include=settings`,
  updateEvent: ({ eventUri }) => `events/${eventUri}`,
  updatePayments: ({ eventUri }) => `events/${eventUri}/settings`,
  monthEvents: ({ monthUpperBound, monthLowerBound }) =>
    `events?end_date_lower_bound=${monthLowerBound}&start_date_upper_bound=${monthUpperBound}&order=start_date%20asc`,
  deleteEvent: ({ eventUri }) => `events/${eventUri}`,

  countries: 'countries',
  regions: ({ countryId }) => `countries/${countryId}/regions`,
  cities: ({ countryId, regionId }) =>
    `countries/${countryId}/regions/${regionId}/cities`,

  emailSenderSettings: 'email_settings',
  createEmailSenderSettings: 'email_settings',
  updateEmailSenderSettings: ({ id }) => `email_settings/${id}`, // TODO - doesn't exist in api
  deleteEmailSenderSettings: ({ id }) => `email_settings/${id}`,

  eventEmails: ({ eventUri }) => `events/${eventUri}/notifications`,
  eventEmail: ({ eventUri, emailId }) =>
    `events/${eventUri}/notifications/${emailId}`,
  createEventEmail: ({ eventUri }) => `events/${eventUri}/notifications`,
  updateEventEmail: ({ eventUri, emailId }) =>
    `events/${eventUri}/notifications/${emailId}`,
  deleteEventEmail: ({ eventUri, emailId }) =>
    `events/${eventUri}/notifications/${emailId}`,

  eventEmailLanguages: ({ eventUri, emailId }) =>
    `events/${eventUri}/notifications/${emailId}/notification_languages`,
  createEventEmailLanguage: ({ eventUri, emailId }) =>
    `events/${eventUri}/notifications/${emailId}/notification_languages`,
  updateEventEmailLanguage: ({ eventUri, emailId, langId }) =>
    `events/${eventUri}/notifications/${emailId}/notification_languages/${langId}`,
  deleteEventEmailLanguage: ({ eventUri, emailId, langId }) =>
    `events/${eventUri}/notifications/${emailId}/notification_languages/${langId}`,

  widgetSettings: ({ eventUri }) => `events/${eventUri}/widget_settings`,
  updateWidgetSettings: ({ eventUri, widgetSettingsId }) =>
    `events/${eventUri}/widget_settings/${widgetSettingsId}`,

  dashBoardFilters: 'stats-filters',

  accountTotalCollected: 'stats/accounts/total-collected',
  accountTotalPaidEvents: 'stats/accounts/total-paid-events',

  totalEvents: 'stats/accounts/total-events',
  totalAttendees: ({ eventUri }) => `stats/events/${eventUri}/total-attendees`,
  totalOrders: ({ eventUri }) => `stats/events/${eventUri}/total-orders`,
  amountCollected: ({ eventUri }) =>
    `stats/events/${eventUri}/amount-collected`,
  purchasedTickets: ({ eventUri }) =>
    `stats/events/${eventUri}/tickets-purchased`,
  ticketsByAttendeeType: ({ eventUri }) =>
    `stats/events/${eventUri}/tickets-by-attendee-type`,
};

const appendFilters = (endpoint, filters) => {
  if (!filters || Object.keys(filters).length === 0) return endpoint;

  const connector = endpoint.includes('?') ? '&' : '?';
  const query = Object.keys(filters)
    .filter((f) => typeof filters[f] !== 'undefined' && filters[f] !== null)
    .map(
      (filter) =>
        `${encodeURIComponent(humps.decamelize(filter))}=${encodeURIComponent(
          filters[filter]
        )}`
    )
    .join('&');

  return `${endpoint}${connector}${query}`;
};

export default (name, params, filters) => {
  const locale = window.location.pathname.split('/')[1] || 'en';
  const endpoint =
    typeof endpoints[name] === 'function'
      ? endpoints[name](params)
      : endpoints[name];
  const endpointWithFilters = appendFilters(endpoint, filters);
  // const defaultHost = 'http://localhost:3002';
  // const defaultHost = 'https://dev-api.eventtia.com';
  // const defaultHost = 'https://dev-core-lb-1902704065.us-east-1.elb.amazonaws.com';
  const defaultHost = 'https://dev-core.eventtia.com';
  return `${
    process.env.REACT_APP_EVENTTIA_HOST || defaultHost
  }/${locale}/v1/${endpointWithFilters}`;
};
