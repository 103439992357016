import { camelizeKeys } from 'humps';

const TOKEN_KEY = 'tower-token';

export const loggedIn = () => !!localStorage.getItem(TOKEN_KEY);

export const setToken = (token) => localStorage.setItem(TOKEN_KEY, token);
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const removeToken = () => localStorage.removeItem(TOKEN_KEY);

export const getTokenContent = (token) => {
  if (!token) return undefined;
  const [, payload] = token.split('.');

  return camelizeKeys(JSON.parse(atob(payload)));
};

export const isValidToken = (accessToken) => {
  if (!accessToken) return false;

  const decoded = getTokenContent(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const ONE_DAY_MS = 86400000;
const tokenExpired = (exp) => {
  let expiredTimer;

  const currentTime = Date.now();

  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  if (timeLeft < ONE_DAY_MS)
    expiredTimer = setTimeout(() => {
      removeToken();
      window.location.href = '/en/login';
    }, timeLeft);
};

export const setSession = (accessToken) => {
  if (isValidToken(accessToken)) {
    setToken(accessToken);
    const { exp } = getTokenContent(accessToken);
    tokenExpired(exp);
  } else removeToken();
};
