export const getMainEntity = (key, obj) => {
  if (!obj?.entities?.[key]) return undefined;
  const [mainEntity] = Object.values(obj.entities[key]);
  return mainEntity;
};

export const getEntityById = (key, id, obj) => {
  if (!obj?.entities?.[key]) return undefined;
  return obj.entities[key][id];
};

export const getEntityByValue = (key, valueKey, value, obj) => {
  if (!obj?.entities?.[key]) return undefined;
  const selectedEntity = Object.values(obj.entities[key]).find(
    (entity) => entity[valueKey] === value
  );
  return selectedEntity;
};

const emptyEntityArray = [];
export const getFullEntityArray = (key, obj) => {
  if (!obj?.entities?.[key]) return emptyEntityArray;
  return Object.values(obj?.entities?.[key]);
};

const emptyEntityObject = {};
export const getFullEntityObject = (key, obj) => {
  if (!obj?.entities?.[key]) return emptyEntityObject;
  return obj.entities?.[key];
};
