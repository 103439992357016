export default {
  en: {
    widgetForm: 'Widget form',
    moduleTitle: 'Registration widget',
    preview: 'Preview',
    brandColors: 'Brand colors',
    mainColor: 'Main color',
    secondaryColor: 'Secondary color',
    advancedOptions: 'Advanced options',
    widgetEmbedding: 'Widget embedding',
    widgetEmbeddingtHelper:
      'You can use a piece of code to embed our registration widget on external websites. Learn how to do it <1>here </1>',
    widgetEmbeddingText:
      'Enable people to register and buy tickets for the event right on your or a third partys website.',
    getEmbedCode: 'Get Embed code',
    cssEditing: 'CSS Editing',
    cssEditingText:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
    editCssCode: 'Edit CSS code',
    placeholder: {
      multilanguageInput: 'Buy tickets now',
    },
    labels: {
      accessButtonText: 'Access button text',
      eventLogo: 'Event logo',
      headerBackgroundImage: 'Header background image',
      headerTextColor: 'Header text color',
    },
    modal: {
      widgetEmbedCode: 'Widget embed code',
      widgetEmbedCodeHelper:
        "Copy and paste this code into the site's HTML where you'd like the widget to appear. ",
      learnToEmbedIt: 'Learn how to embed it',
      copyScript:
        'Copy and paste this script inside the <body> tag, on the page you wanted to show the widget',
      copyButton:
        'Then copy and paste this button on the section you wanted to place the Widget',
      copyCode: 'Copy code',
    },
  },
};
