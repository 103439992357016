import React from 'react';
import { makeStyles } from 'tss-react/mui';
import MessageWithLink from 'eventtia-ui-components/lib/MessageWithLink';
import { useTranslation } from 'react-i18next';
import useRoutes from '../../hooks/useRoutes';
import LoggedOutLayoutContainer from '../../components/LoggedOutLayoutContainer';
import LoginForm from '../../components/LoginForm';
import image from '../../images/loginImage.png';

const useStyles = makeStyles()((theme) => ({
  form: {
    marginBottom: theme.spacing(2),
  },
  messageWithLink: {
    padding: theme.spacing(1, 0),
  },
}));

const Login = () => {
  const { classes } = useStyles();
  const { t } = useTranslation(['login', 'signUp']);
  const { urlTo } = useRoutes();

  return (
    <LoggedOutLayoutContainer title={t('login')} image={image}>
      <LoginForm />
      <MessageWithLink
        className={classes.messageWithLink}
        message={t('messages.noAccount')}
        to={urlTo('sign-up')}
        linkMessage={t('signUp:actions.createAccount')}
        align="center"
      />
      <MessageWithLink
        message={t('messages.forgotPassword')}
        to={urlTo('forgot-password')}
        linkMessage={t('actions.clickHere')}
        align="center"
      />
    </LoggedOutLayoutContainer>
  );
};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
