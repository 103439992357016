const getScrollParent = (node) => {
  const isElement = node instanceof HTMLElement;
  if (!node || !isElement) return null;
  const overflowY = isElement && window.getComputedStyle(node).overflowY;
  const position = isElement && window.getComputedStyle(node).position;
  const isScrollable =
    position === 'fixed' && overflowY !== 'visible' && overflowY !== 'hidden';
  if (isScrollable && node.scrollHeight >= node.clientHeight) return node;
  return (
    getScrollParent(node.parentNode) || document.scrollingElement || window
  );
};

export default getScrollParent;
