import React from 'react';
import { Navigate } from 'react-router-dom';
import Loader from 'eventtia-ui-components/lib/Loader';
import useAuthContext from '../hooks/useAuthContext';
import useRoutes from '../hooks/useRoutes';

const GuestGuard = ({ children }) => {
  const { isInitialized, isAuthenticated } = useAuthContext();
  const { urlTo } = useRoutes();

  if (isAuthenticated) return <Navigate to={urlTo('events')} />;

  if (!isInitialized) return <Loader loading variant="absolute" />;

  return children;
};

export default GuestGuard;
