import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import RadioButtons from 'eventtia-ui-components/lib/RadioButtons';

const RHFRadioButtons = ({ name, ...other }) => {
  const { control } = useFormContext();
  const defultValue = [];

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { ref, value, onChange, ...field },
        fieldState: { error },
      }) => {
        return (
          <RadioButtons
            {...field}
            inputRef={ref}
            id={name}
            value={value || defultValue}
            handleChange={onChange}
            error={error?.message}
            {...other}
          />
        );
      }}
    />
  );
};

RHFRadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
};

export default RHFRadioButtons;
