export default {
  en: {
    globalDashboard: 'Global dashboard',
    nextEvents: 'Upcoming events',
    mapOfEvents: 'Map of events',
    attendeesOverview: 'Attendees overview',
    typeOfEvents: 'Type of events',
    numberOfVirtualEvents: 'Number of virtual events',
    numberOfEventsOnSite: 'Number of events on site',
    numberOfPaidEvents: 'Number of paid events',
    numberOfAttendees: 'Number of registered attendees',
    totalCheckedAttendees: 'Number of checked attendees',
    totalSales: 'Total sales',
    upFromLastMonth: '% up from last month',
    eventsTable: {
      eventName: 'Event name',
      startDate: 'Start date',
      location: 'Location',
    },
    attendeesTable: {
      eventName: 'Event name',
      eventType: 'Event type',
      registered: 'Registered',
      checkedIn: 'Checked-in',
    },
    events: 'events',
    filters: {
      from: 'From',
      to: 'To',
      city: 'City',
      administrativeAreas: 'Administrative areas',
      filter: 'Filter',
      clearFilters: 'Clear filters',
    },
    noAttendeesOrEventsToShow: 'There are no attendees or events to show',
    noUpcomingEvents: 'There are no upcoming events to show',
    noEventsMap: 'There are no events to show in the map',
    noAttendeesOverview: 'There are no attendees data to show',
    noCustomGraphs: 'There are no custom graphs to show',
    showCustomFilters: 'Show custom filters',
    hideCustomFilters: 'Hide custom filters',
    customGraphs: 'Custom Graphs',
  },
  es: {
    globalDashboard: 'Dashboard global',
    numberOfEvents: 'Número de eventos',
    numberOfPaidEvents: 'Número de eventos pagos',
    numberOfAttendees: 'Número de asistentes',
    totalSales: 'Total en ventas',
    upFromLastMonth: '% más que el mes pasado',
    eventsTable: {
      eventName: 'Nombre de evento',
      startDate: 'Fecha de inicio',
      location: 'Locación',
    },
    attendeesTable: {
      eventName: 'Nombre de evento',
      eventType: 'Tipo de evento',
      registered: 'Registrados',
      checkedIn: 'Checked-in',
    },
    events: 'eventos',
    filters: {
      from: 'Desde',
      to: 'Hasta',
      city: 'Ciudad',
      filter: 'Filtrar',
      clearFilters: 'Borrar filtros',
    },
    noAttendeesOrEventsToShow: 'No hay eventos o asistentes para mostrar',
    noUpcomingEvents: 'No hay próximos eventos',
    noEventsMap: 'No hay eventos para mostrar en el mapa',
    noAttendeesOverview: 'No hay datos de asistentes para mostrar',
    noCustomGraphs: 'No hay gráficas personalizadas para mostrar',
    showCustomFilters: 'Mostrar filtros personalizados',
    hideCustomFilters: 'Esconder filtros personalizados',
    customGraphs: 'Gráficos personalizados',
  },
};
