import { createSlice } from '@reduxjs/toolkit';

export const sidebarTypes = {
  event: 'event',
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    sidebarOpen: false,
    sidebarType: '',
  },
  reducers: {
    toggleSidebarOpen: (state, action) => ({
      ...state,
      sidebarOpen: action.payload,
    }),
    setSidebarType: (state, action) => ({
      ...state,
      sidebarType: action.payload ?? '',
    }),
  },
});

export const { toggleSidebarOpen, setSidebarType } = uiSlice.actions;

export const selectSidebarOpen = (state) => state.ui.sidebarOpen;
export const selectSidebarType = (state) => state.ui.sidebarType;

export default uiSlice.reducer;
