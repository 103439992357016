import { configureStore, combineReducers } from '@reduxjs/toolkit';

import entitiesReducer from '../reducers/entities';
import fetchStatusReducer from '../reducers/fetchStatus';
import filtersReducer from '../reducers/filters';
import metaReducer from '../reducers/meta';
import uiReducer from '../reducers/ui';

import filterMiddleware from '../middlewares/filters';
import apiMiddleware from '../middlewares/api';
import authMiddleware from '../middlewares/auth';

import { LOGOUT } from '../actions/app';

const appReducer = combineReducers({
  entities: entitiesReducer,
  fetchStatus: fetchStatusReducer,
  filters: filtersReducer,
  meta: metaReducer,
  ui: uiReducer,
});

const rootReducer = (state, action) =>
  appReducer(action.type === LOGOUT ? undefined : state, action);

const initStore = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActionPaths: [
            'Call API.options.body',
            'Call API.passToAction',
          ],
        },
      }).concat(filterMiddleware, apiMiddleware, authMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
  });

export default initStore;
