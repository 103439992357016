import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { joiResolver } from '@hookform/resolvers/joi';

const useCustomForm = (schema, deps = {}) => {
  const { t } = useTranslation('global', 'signUp');
  const customSchema = schema.messages({
    'any.required': t('formError.requiredField'),
    'string.empty': t('formError.requiredField'),
    'string.max': t('formError.tooLong', { limit: '{#limit}' }),
    'string.email': t('formError.enterValidEmail'),
    'string.min': t('formError.invalidPassword'),
    'any.only': t('formError.errorPasswordMatch'),
    'string.pattern.base': t('signUp:formError.errorPasswordValidation'),
  });

  const {
    register: originalRegister,
    setValue: originalSetValue,
    clearErrors,
    ...fns
  } = useForm({
    resolver: joiResolver(customSchema),
    ...deps,
  });

  // our inputs use inputRef instead of ref
  const register = useCallback(
    (inputName, validation) => {
      const { ref, ...rest } = originalRegister(inputName, validation);
      return { inputRef: ref, id: inputName, ...rest };
    },
    [originalRegister]
  );

  const setValue = useCallback(
    (inputName, value) => {
      // this is necessary due to controlled inputs.
      clearErrors(inputName);
      originalSetValue(inputName, value);
    },
    [originalSetValue]
  );

  return {
    register,
    setValue,
    clearErrors,
    ...fns,
  };
};

export default useCustomForm;
