import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { IconButton } from '@mui/material';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import CloseIcon from '@mui/icons-material/Close';

import announcementsConfig from '../../config/announcements';

const { sitewide: config } = announcementsConfig;

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    boxShadow: theme.customShadows.small,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
  },
}));

const SidewideAnnouncement = () => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(true);

  return (
    config.enabled &&
    open && (
      <div className={classes.root}>
        <NotificationImportantIcon />
        <div dangerouslySetInnerHTML={{ __html: config.message }} />
        <IconButton
          onClick={() => setOpen(false)}
          sx={{ color: 'primary.contrastText' }}
        >
          <CloseIcon />
        </IconButton>
      </div>
    )
  );
};

export default SidewideAnnouncement;
