import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import QrCodeIcon from '@mui/icons-material/QrCode';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

export const ticketTypeActions = [
  'attendee_created',
  'attendee_rejected',
  'attendee_confirmed',
];

export const actionOptions = [
  // 'account_created', // this one can't be created
  // 'welcome_email', // for users, can't be created either
  // 'recover_password', // can't be created either
  ...ticketTypeActions,
];

export const templates = {
  essential: {
    Icon: DescriptionOutlinedIcon,
  },
  qrCode: {
    Icon: QrCodeIcon,
  },
  default: {
    Icon: InsertDriveFileOutlinedIcon,
  },
};
