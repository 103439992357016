import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EventIcon from '@mui/icons-material/Event';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const useStyles = makeStyles()((theme) => ({
  startAndEndDate: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  calendar: {
    fontWeight: 'bold',
    padding: '0px',
    color: '#0033A0',
  },
  noMargin: {
    marginBottom: '0px',
  },
}));

const DateB = ({ startDate, endDate, timezone, color }) => {
  const { classes } = useStyles();
  const { t } = useTranslation('composerTemplates');

  return (
    <>
      <Typography variant="h1">{t('date')}</Typography>
      <div className={classes.startAndEndDate}>
        <Typography variant="h4" className={classes.noMargin}>
          {startDate}
        </Typography>
        <ChevronRightIcon style={{ color }} />
        <Typography variant="h4" className={classes.noMargin}>
          {endDate} {t('timezone', { gmt: timezone })}
        </Typography>
      </div>
      <Button className={classes.calendar} startIcon={<EventIcon />}>
        {t('addCalendar')}
      </Button>
    </>
  );
};

DateB.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  timezone: PropTypes.string,
  color: PropTypes.string,
};

DateB.defaultProps = {
  startDate: '',
  endDate: '',
  timezone: '',
  color: '',
};

export default DateB;
