const PAYU = 'payu';
const PAYPAL = 'paypal';
const STRIPE = 'stripe';

export { PAYU, PAYPAL, STRIPE };

export default {
  EVENTS: {
    TABS: {
      CURRENT: 'current',
      PAST: 'past',
      TEMPLATES: 'templates',
    },
    TYPES: {
      EVENT: 'Event',
      TEMPLATE: 'Template',
    },
  },
  VIEWS: {
    CALENDAR: 'calendar',
    LIST: 'list',
  },
};

export const ATTENDANCE_MODE = {
  PHYSICAL: 'offline',
  VIRTUAL: 'online',
  HYBRID: 'mixed',
};
