import { useQuery as baseUseQuery } from '@tanstack/react-query';
import {
  getMainEntity,
  getFullEntityArray,
  getFullEntityObject,
} from '../../api/extractors';

const extractors = {
  'all-array': (key, obj) => getFullEntityArray(key, obj),
  'all-object': (key, obj) => getFullEntityObject(key, obj),
  main: (key, obj) => getMainEntity(key, obj),
};

const useQuery = ({ select, ...other }) => {
  const selectFunction = select
    ? (data) => {
        const { entities, ...otherData } = data;
        const selectedData = { ...otherData };
        Object.entries(select).forEach(([key, value]) => {
          const [entity, extratorType, extraProcessor] = value;

          const extractedData = extractors[extratorType](entity, data);

          selectedData[key] = extraProcessor
            ? extraProcessor(extractedData)
            : extractedData;
        });
        return selectedData;
      }
    : undefined;

  return baseUseQuery({
    select: typeof select === 'function' ? select : selectFunction,
    ...other,
  });
};

export default useQuery;
