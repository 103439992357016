import ShortTextIcon from '@mui/icons-material/ShortText';
import SubjectIcon from '@mui/icons-material/Subject';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import LocationCityIcon from '@mui/icons-material/LocationCity';
// import NoteAddIcon from '@mui/icons-material/NoteAdd'; // OJO: Icono en diseno no encontrado
import ImageIcon from '@mui/icons-material/Image';
import GavelIcon from '@mui/icons-material/Gavel';
import LabelIcon from '@mui/icons-material/Label';

const fieldTypes = {
  TEXT: 'Fields::TextField',
  TEXT_AREA: 'Fields::TextAreaField',
  LABEL: 'Fields::LabelField',
  SINGLE_SELECT: 'Fields::SingleSelectionField',
  MULTIPLE_SELECT: 'Fields::MultipleSelectionField',
  DATE: 'Fields::DateTimeField',
  CITY: 'Fields::CityField',
  IMAGE: 'Fields::ImageField',
  // FILE: 'Fields::FileField',
  TERMS: 'Fields::TermsField',
};

export { fieldTypes as FIELDS };

const fieldTypeLabels = {
  [fieldTypes.TEXT]: {
    name: fieldTypes.TEXT,
    icon: ShortTextIcon,
    label: 'forms.labels.text',
  },
  [fieldTypes.TEXT_AREA]: {
    name: fieldTypes.TEXT_AREA,
    icon: SubjectIcon,
    label: 'forms.labels.textArea',
  },
  [fieldTypes.LABEL]: {
    name: fieldTypes.LABEL,
    icon: LabelIcon,
    label: 'forms.labels.label',
  },
  [fieldTypes.SINGLE_SELECT]: {
    name: fieldTypes.SINGLE_SELECT,
    icon: RadioButtonCheckedIcon,
    label: 'forms.labels.singleSelection',
  },
  [fieldTypes.MULTIPLE_SELECT]: {
    name: 'Fields::MultipleSelectionField',
    icon: CheckBoxIcon,
    label: 'forms.labels.multipleSelection',
  },
  [fieldTypes.DATE]: {
    name: fieldTypes.DATE,
    icon: DateRangeIcon,
    label: 'forms.labels.dateField',
  },
  [fieldTypes.CITY]: {
    name: fieldTypes.CITY,
    icon: LocationCityIcon,
    label: 'forms.labels.city',
  },
  [fieldTypes.IMAGE]: {
    name: fieldTypes.IMAGE,
    icon: ImageIcon,
    label: 'forms.labels.image',
  },
  // [fieldTypes.FILE]: {
  //   name: 'Fields::FileField',
  //   icon: NoteAddIcon,
  //   label: 'forms.labels.file',
  // },
  [fieldTypes.TERMS]: {
    name: 'Fields::TermsField',
    icon: GavelIcon,
    label: 'forms.labels.terms',
  },
};

export default fieldTypeLabels;
