import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  banner: {
    maxHeight: '100%',
    width: 'auto',
    position: 'relative',
    paddingTop: '56.25%' /* 16:9 Aspect Ratio */,
  },
  background: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: theme.spacing(1.25),
    opacity: '1',
    position: 'absolute',
    top: '0',
  },
}));

const BannerB = ({ banner }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.banner}>
      <img
        className={classes.background}
        style={{ backgroundImage: `url('${banner}')` }}
        width="100%"
        height="100%"
        alt=""
      />
    </div>
  );
};

BannerB.propTypes = {
  banner: PropTypes.string,
};

BannerB.defaultProps = {
  banner: '',
};

export default BannerB;
