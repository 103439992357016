import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RadioButtons from 'eventtia-ui-components/lib/RadioButtons';

const EventTimeFormatSelector = ({ id, value, handleChange }) => {
  const { t } = useTranslation(['events']);

  const timeFormatOptions = [
    { label: t('forms.amPm'), value: 'am_pm' },
    { label: t('forms.hours24'), value: 'hours_24' },
  ];

  return (
    <RadioButtons
      row
      id={id}
      value={value}
      options={timeFormatOptions}
      handleChange={handleChange}
      label={t('forms.hourFormat')}
    />
  );
};

EventTimeFormatSelector.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default EventTimeFormatSelector;
