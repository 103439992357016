import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import ColorPicker from '../ColorPicker';

const RHFColorPicker = ({ name, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <ColorPicker {...field} error={error?.message} {...other} />
      )}
    />
  );
};

RHFColorPicker.propTypes = {
  name: PropTypes.string.isRequired,
};

export default RHFColorPicker;
