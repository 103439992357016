import merge from 'lodash/merge';
import normalize from 'jsonapi-normalizer';
import { camelizeKeys, decamelizeKeys, camelize } from 'humps';
import ApiError from '../helpers/ApiError';
import getEndpoint from '../helpers/endpoints';
import { getDefaultOptions, fetchConfig } from '../actions/callApi';

const getLastPage = (links) => {
  return parseInt(
    links?.last?.match(/page(?:\[|%5B)number(?:\]|%5D)=(\d+)/)[1],
    10
  );
};

const parseJsonApiResponse = (json) => {
  const { result, ...response } = camelizeKeys(normalize(json));

  response.links = json.links;

  if (json.data.length) {
    const type = camelize(json.data[0].type);

    response.order = result[type];
  }

  if (json.meta) response.meta = camelizeKeys(json.meta);

  return response;
};

const mergePages = (cumulativeResponse, newPageResponse) => {
  const { order: nextPageOrder, ...otherData } = newPageResponse;
  merge(cumulativeResponse, otherData);
  cumulativeResponse.order.push(...nextPageOrder);
};

const fetchData = async (key, params, filters) => {
  const endpoint = getEndpoint(key, params, filters);
  const signal = params?.signal;

  const config = { ...fetchConfig[key] };
  let { options } = config;
  if (typeof options === 'function') options = options(params);

  const newOptions = merge(getDefaultOptions(), options, { signal });

  if (
    newOptions?.headers?.['Content-Type'] === 'application/json' &&
    newOptions.body
  )
    newOptions.body = JSON.stringify(decamelizeKeys(newOptions.body));

  const serverResponse = await fetch(endpoint, newOptions);
  const json = await serverResponse.json();

  if (!serverResponse.ok)
    throw new ApiError({
      ...json,
      status: serverResponse.status,
    });

  if (!json?.data) return json;

  const response = parseJsonApiResponse(json);

  if (config.fetchAllPages || params?.fetchAllPages) {
    const lastPage = getLastPage(response.links);
    const nextRequests = [];
    for (let newPage = 2; newPage <= lastPage; newPage += 1) {
      const newPageEndpoint = getEndpoint(key, params, {
        ...filters,
        'page[number]': newPage,
      });

      nextRequests.push(
        fetch(newPageEndpoint, newOptions).then(async (nextServerResponse) => {
          const nextJson = await nextServerResponse.json();

          if (!nextServerResponse.ok)
            throw new ApiError({
              ...nextJson,
              status: nextServerResponse.status,
            });

          return parseJsonApiResponse(nextJson);
        })
      );
    }

    const nextResponses = await Promise.all(nextRequests);

    nextResponses.forEach((nextResponse) => {
      mergePages(response, nextResponse);
    });
  }

  return response;
};

export default fetchData;
