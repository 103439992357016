import { camelize } from 'humps';
import { parseErrorFromBack, parseFieldErrors } from 'core-error-locales';

export default (
  rawErrors,
  handleGlobalError,
  handleFieldError,
  baseErrorMsg
) => {
  const errors =
    typeof rawErrors === 'string' ? { global: [rawErrors] } : rawErrors;
  const currentErrors = [];
  if (rawErrors) {
    const parsedError = parseErrorFromBack(errors);
    const errorMessageArray = parseFieldErrors(parsedError);
    errorMessageArray.forEach(({ field, message }) => {
      if (field === 'base' || field === 'error')
        handleGlobalError({
          title: baseErrorMsg.title,
          message,
          type: 'error',
        });
      else
        currentErrors.push({
          name: camelize(field),
          type: 'manual',
          message,
        });
    });
    handleFieldError(currentErrors);
  }
};
