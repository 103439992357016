import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import pageProperties from '../pageProperties';
import moment from '../../helpers/dates';

const useStyles = makeStyles()((theme) => ({
  register: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: '#F6F6FA 0% 0% no-repeat padding-box',
    borderRadius: theme.spacing(1.25),
    opacity: '1',
    padding: theme.spacing(3),
    textAlign: 'left',
    height: '100%',
  },
  eventTitle: {
    marginBottom: theme.spacing(0),
  },
  registeredPeople: {
    marginBottom: theme.spacing(9),
    letterSpacing: theme.spacing(0),
    fontWeight: 'bold',
    color: '#0033A0',
    opacity: '1',
  },
  registrationTime: {
    gridArea: 'registrationTime',
  },
  registerButton: {
    gridArea: 'registerButton',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: theme.spacing(1.5),
    width: '100%',
    color: '#FFFFFF',
    opacity: '1',
  },
}));

const RegisterB = ({ data, eventName }) => {
  const { classes } = useStyles();
  const { t } = useTranslation('composerTemplates');
  const { eventUri } = useParams();
  const registeredPeople = data?.registeredPeople;
  const registrationEndDate = moment(
    pageProperties.dates?.registrationEndDate
  ).format('h:mm a, ll');

  return (
    <div className={classes.register}>
      <div className={classes.eventTitle}>
        <Typography variant="h1">{eventName}</Typography>
        {/* it isn't the correct number, pending calculation */}
        <Typography variant="h4" className={classes.registeredPeople}>
          {registeredPeople} {t('registeredPeople')}
        </Typography>
      </div>
      <div className={classes.registerData}>
        <Typography variant="h4" className={classes.registrationTime}>
          {t('registrationTime', {
            time: registrationEndDate,
          })}
        </Typography>
        <tower-terminal event-uri={eventUri} />
      </div>
    </div>
  );
};

RegisterB.propTypes = {
  data: PropTypes.shape({
    registeredPeople: PropTypes.number,
    whiteLabel: PropTypes.bool,
    availableLanguage: PropTypes.objectOf(PropTypes.string),
    eventType: PropTypes.string,
    location: PropTypes.string,
    map: PropTypes.string,
    shareLink: PropTypes.string,
  }),
  eventName: PropTypes.string,
  // color: PropTypes.string,
};

RegisterB.defaultProps = {
  data: '',
  eventName: '',
  // color: '',
};

export default RegisterB;
