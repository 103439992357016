import { QueryClient } from '@tanstack/react-query';

import fetchData from '../api/fetchData';

const defaultQueryFn = async ({ queryKey }) => fetchData(...queryKey);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
    },
  },
});

export default queryClient;
