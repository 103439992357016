/* eslint-disable no-await-in-loop */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import callApi from '../../actions/callApi/index';

const usePrefetchEvent = () => {
  const dispatch = useDispatch();
  const { eventUri } = useParams();

  const currentEvent = useSelector(({ entities }) =>
    Object.values(entities.events || {}).find(
      ({ eventUri: uri }) => uri === eventUri
    )
  );

  const generalAttendeeTypes =
    useSelector(({ entities }) => entities.attendeeTypes) || {};

  const attendeeTypes = Object.fromEntries(
    Object.entries(generalAttendeeTypes).filter(
      ([, { eventId }]) => eventId?.toString() === currentEvent?.id
    )
  );
  const { settings } = currentEvent || {};
  const eventSettings = useSelector(
    ({ entities }) => entities.eventSettings?.[settings?.id]
  );
  const isFetching = useSelector(
    ({
      fetchStatus: {
        ticketTypes: { isFetching: isFetchingAttendeeTypes },
        currentEvent: { isFetching: isFetchingEvent },
      },
    }) => isFetchingAttendeeTypes || isFetchingEvent
  );
  const error = useSelector(
    ({ fetchStatus: { currentEvent: event } }) => event.error
  );

  useEffect(() => {
    if (eventUri && !eventSettings && !isFetching)
      dispatch(callApi('currentEvent', { eventUri }));
  }, [dispatch, eventUri]);

  useEffect(() => {
    if (eventUri && !Object.values(attendeeTypes).length && !isFetching)
      dispatch(callApi('ticketTypes', { eventUri }));
  }, [dispatch, eventUri]);

  return {
    error,
    isFetching,
    currentEvent,
    attendeeTypes,
    eventSettings,
  };
};

export default usePrefetchEvent;
