import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import SingleSwitch from 'eventtia-ui-components/lib/SingleSwitch';

const RHFSingleSwitch = ({ name, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, value, ...field }, fieldState: { error } }) => (
        <SingleSwitch
          {...field}
          inputRef={ref}
          id={name}
          checked={value}
          error={error?.message}
          {...other}
        />
      )}
    />
  );
};

RHFSingleSwitch.propTypes = {
  name: PropTypes.string.isRequired,
};

export default RHFSingleSwitch;
