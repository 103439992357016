import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Box, Typography, Popover, IconButton } from '@mui/material';
import { HexColorPicker, HexColorInput } from 'react-colorful';

import ColorizeIcon from '@mui/icons-material/Colorize';

const useStyles = makeStyles()((theme, _params, classes) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    height: 88,
    borderColor: theme.palette.lightGrey.dark,
    borderRadius: 2 * theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    display: 'flex',
    columnGap: theme.spacing(2),
  },
  popoverPaper: {
    borderRadius: 2 * theme.shape.borderRadius,
    overflow: 'visible',
  },
  preview: {
    position: 'relative',
    height: 55,
    width: 55,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    cursor: 'pointer',
    [`& .${classes.floatingButton}`]: {
      display: 'none',
    },
    '&:hover': {
      boxShadow: theme.shadows[2],
      [`& .${classes.floatingButton}`]: {
        display: 'flex',
      },
    },
  },
  floatingButton: {
    position: 'absolute',
    top: theme.spacing(-1.5),
    right: theme.spacing(-1.5),
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5),
    boxShadow: theme.shadows[1],
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  info: {
    flexGrow: 1,
  },
  label: {
    lineHeight: '26px',
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.darkGrey.light,
  },
  colorInput: {
    height: 30,
    border: `1px solid ${theme.palette.darkGrey.light}`,
    borderRadius: 5,
    padding: 8,
    color: theme.palette.darkGrey.main,
    width: '100%',
  },
}));

const ColorPicker = ({ label, value, onChange }) => {
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const openPicker = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box className={classes.root}>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        classes={{
          paper: classes.popoverPaper,
        }}
      >
        <HexColorPicker color={value} onChange={onChange} />
      </Popover>
      <Box
        onClick={openPicker}
        className={classes.preview}
        sx={{
          backgroundColor: value,
        }}
      >
        <IconButton className={classes.floatingButton}>
          <ColorizeIcon color="primary" fontSize="inherit" />
        </IconButton>
      </Box>
      <Box className={classes.info}>
        <Typography className={classes.label}>{label}</Typography>
        <HexColorInput
          className={classes.colorInput}
          color={value}
          onChange={onChange}
        />
      </Box>
    </Box>
  );
};

ColorPicker.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ColorPicker;
