export default {
  en: {
    addCalendar: 'ADD TO CALENDAR',
    date: 'Date & Time',
    eventtia: 'Events manager software',
    location: 'Location',
    poweredBy: 'Powered by ',
    registeredPeople: 'registered people',
    registerNow: 'REGISTER NOW',
    registrationTime: 'Registration will end in {{time}} days',
    timezone: '(GMT {{gmt}})',
  },
};
