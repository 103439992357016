import { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { injectParams, prependLocalePath } from '../../routes';

export default () => {
  const navigate = useNavigate();
  const params = useParams();

  const urlTo = useCallback(
    (id, paramValues = {}) =>
      injectParams(prependLocalePath(id), { ...params, ...paramValues }),
    [params]
  );

  const goTo = useCallback(
    (id, paramValues) => navigate(urlTo(id, paramValues)),
    [urlTo, navigate]
  );

  const goBack = () => {
    navigate(-1);
  };

  return {
    goTo,
    urlTo,
    goBack,
  };
};
