import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

const useStyles = makeStyles()((theme) => ({
  location: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
  },
  chip: {
    margin: theme.spacing(0, 3, 0),
    opacity: '1',
    fontSize: 'smaller',
    fontWeight: '400',
    padding: theme.spacing(0, 3),
  },
}));

const LocationB = ({ color, type, location, map }) => {
  const { classes } = useStyles();
  const { t } = useTranslation('composerTemplates');

  return (
    <div className={classes.location}>
      <Typography variant="h1">
        {t('location')}
        <Chip
          className={classes.chip}
          style={{
            color: `${color}`,
          }}
          label={type}
        />
      </Typography>
      <Typography variant="h4">{location}</Typography>
      <img src={map} width="100%" height="auto" alt="" />
    </div>
  );
};

LocationB.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string,
  location: PropTypes.string,
  map: PropTypes.string,
};

LocationB.defaultProps = {
  color: '',
  type: '',
  location: '',
  map: '',
};

export default LocationB;
