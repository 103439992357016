import { createTheme as createMuiTheme } from '@mui/material/styles';
import { rawTheme } from 'eventtia-ui-components/lib/styles/theme';
import cloneDeep from 'lodash/cloneDeep';

const createTheme = ({ fontFamily, secondaryColor }) => {
  const newRawTheme = cloneDeep(rawTheme);
  if (fontFamily) newRawTheme.typography.fontFamily = fontFamily;
  if (secondaryColor) newRawTheme.palette.secondary = secondaryColor;
  return createMuiTheme(newRawTheme);
};

export default createTheme;
