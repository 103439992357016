import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const useStyles = makeStyles()((theme) => ({
  footer: {
    color: '#FFFFFF',
    width: '100%',
    height: 'auto',
    padding: theme.spacing(4, 14),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerEventtia: {
    marginBottom: '0',
    color: '#FFFFFF',
  },
}));

const FooterB = ({ color }) => {
  const { classes } = useStyles();
  const { t } = useTranslation('composerTemplates');

  return (
    <div
      className={classes.footer}
      style={{
        backgroundColor: `${color}`,
      }}
    >
      <Typography variant="h4" className={classes.footerEventtia}>
        {t('poweredBy')}
        <img
          src="https://s3.amazonaws.com/eventtia-stage/event_files/94754/original/eventtialogo16576400781657640078.png?1657640078"
          alt="eventtia"
        />
      </Typography>
      <Link
        href="https://www.eventtia.com/en/event-management-platform"
        className={classes.footerEventtia}
        underline="none"
      >
        {t('eventtia')}
      </Link>
    </div>
  );
};

FooterB.propTypes = {
  color: PropTypes.string,
};

FooterB.defaultProps = {
  color: '',
};

export default FooterB;
