import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles()(() => ({
  description: {
    height: 'auto',
  },
}));

const DescriptionB = ({ data }) => {
  const { classes } = useStyles();
  const { locale } = useParams();
  const { title, content } = data;

  return (
    <div className={classes.description}>
      <Typography variant="h1">{title?.[locale]}</Typography>
      <Typography variant="h4">{content?.[locale]}</Typography>
    </div>
  );
};

DescriptionB.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.objectOf(PropTypes.string),
    content: PropTypes.objectOf(PropTypes.string),
  }),
};

DescriptionB.defaultProps = {
  data: {},
};

export default DescriptionB;
