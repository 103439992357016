export default {
  en: {
    latestPayment: 'Latest payment',
    plan: 'Plan',
    active: 'Active',
    pastDue: 'Past due',
    date: 'Date',
    amount: 'Amount',
    expiresOn: 'Expires on',
    dueOn: 'Due on',
    selected: 'Selected',
    paymentOptions: 'Payment options',
    successPayment: 'Successful subscription!',
    onboardingSuccess: 'Successful onboarding!',
    onboardingFail: 'Fail onboarding!',
    failedPayment: 'The subscription could not be complete',
    monthly: 'Monthly',
    yearly: 'Yearly',
    biweekly: 'Biweekly',
    actions: {
      goBack: 'Go back',
      startNow: 'Start now',
      choosePlan: 'Choose plan',
      changePlan: 'Change plan',
      managePayments: 'Change payment method',
      stripeOnboarding: 'Stripe onboarding',
      clickHere: 'Click here to set it up',
    },
    info: {
      trialEndedTitle: 'Hey, your free trial has ended',
      trialEndedInfo:
        'If you want to keep using eventtia click on Change plan to get a premium subscription',
      successPaymentText:
        'Congratulations, you now have a new subscription. Click the button below to view it.',
      onboardingSuccessText:
        'You have completed your stripe onboarding. You will be now able to receive payments.',
      onboardingFailedText: 'Something has gone wrong in the onboarding.',
      noPaymentEnabled:
        "You haven't completed your stripe onboarding. You will not be able to receive payments until you finish this. <1>Click here to set it up</1>",
      failedPaymentText:
        'Something has gone wrong in the subscription payment.',
      noSubscriptionText:
        "You don't have an active plan. Please choose one by clicking the button bellow",
      activatingPlan:
        'We are processing your plan, this may take a few seconds',
      paymentNotCompleted: 'Payment not completed',
      paymentNotCompletedTitle: 'The subscription may not have been completed',
      paymentNotCompletedInfo:
        'Something went wrong with the subscription proccess, we suggest you verify that your payment information is in order and retry.',
      cardChecks: {
        checkIn: 'Check in',
        sponsors: 'Sponsors',
        editor: 'Website editor',
        registrations: 'Included registrations',
      },
    },
    currency: 'Currency',
    vatAlias: 'Tax denomination',
    taxValue: 'Tax cost',
    eventBudget: 'Event budget',
    secretApiKey: 'Secret Api key',
    publishableApiKey: 'Publishable api key',
    apiKey: 'Api key',
    apiLogin: 'Api login',
    merchantId: 'Merchant id',
    accountId: 'Account id',
    testMode: 'Test mode',
    productionKey: 'Production key',
    sandboxKey: 'Sandbox key',
    messages: {
      errors: {
        updatingPayments: 'Error updating payment options',
        updatingPaymentsMsg: 'There was an issue updating payment options',
      },
    },
  },
  es: {
    latestPayment: 'Último pago',
    plan: 'Plan',
    active: 'Activo',
    pastDue: 'Vencido',
    date: 'Fecha',
    amount: 'Monto',
    expiresOn: 'Vence',
    dueOn: 'Vencimiento',
    selected: 'Seleccionado',
    paymentOptions: 'Opciones de pago',
    successPayment: '¡Suscripción exitosa!',
    failedPayment: 'La suscripción no se pudo completar',
    actions: {
      goBack: 'Volver',
      choosePlan: 'Elegir plan',
      changePlan: 'Cambiar plan',
      managePayments: 'Cambiar método de pago',
    },
    info: {
      successPaymentText:
        'Felicidades, ahora tienes una nueva suscripción. Click en el botón acontinuación para verla.',
      failedPaymentText: 'Algo ha salido mal en el pago de la suscripción.',
      noSubscriptionText:
        'No tienes un plan activo. Elija uno haciendo clic en el botón de abajo.',
      activatingPlan:
        'Estamos activando tu plan, esto puede tomar unos segundos.',
    },
    currency: 'Moneda',
    vatAlias: 'Denominación de impuesto',
    taxValue: 'Valor del impuesto',
    eventBudget: 'Event budget',
    secretApiKey: 'Secret Api key',
    publishableApiKey: 'Publishable api key',
    apiKey: 'Api key',
    apiLogin: 'Api login',
    merchantId: 'Merchant id',
    accountId: 'Account id',
    testMode: 'Modo de prueba',
    productionKey: 'Production key',
    sandboxKey: 'Sandbox key',
    messages: {
      errors: {
        updatingPayments: 'Error updating payment options',
        updatingPaymentsMsg: 'There was an issue updating payment options',
      },
    },
  },
  fr: {},
  de: {},
};
