export default {
  en: {
    actions: {
      addTag: 'Add tag',
      createTag: 'Create tag',
      saveTag: 'Save tag',
      manageTags: 'Manage tags',
    },
    labels: {
      chooseTagName: 'Choose the name of the label you want to create',
      tagName: 'Tag name',
    },
  },
  es: {
    actions: {
      addTag: 'Agregar etiqueta',
      createTag: 'Crear etiqueta',
      saveTag: 'Guardar etiqueta',
      manageTags: 'Administrar etiquetas',
    },
    labels: {
      chooseTagName: 'Elige el nombre de la etiqueta que quieres crear',
      tagName: 'Nombre de la etiqueta',
    },
  },
  fr: {},
  de: {},
};
