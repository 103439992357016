import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import Message from 'eventtia-ui-components/lib/Message';
import useDidUpdate from '../../hooks/useDidUpdate';
import handleResponseError from '../../helpers/responseError';
import getScrollParent from '../../helpers/getScrollParent';

const useStyles = makeStyles()((theme) => ({
  content: {
    padding: theme.spacing(3),
  },
  notificationMessage: {
    whiteSpace: 'pre-line',
    marginBottom: theme.spacing(2),
  },
}));

const withFormMessage =
  (Component) =>
  ({ ...props }) => {
    const { classes } = useStyles();
    const { t } = useTranslation('global');
    const [message, setMessage] = useState({});
    const [customErrors, setCustomErrors] = useState([]);
    const { error, success } = props;
    const containerRef = useRef(null);
    useDidUpdate(() => {
      handleResponseError(error, setMessage, setCustomErrors, {
        title: t('title.error'),
        message: t('message.error'),
      });
    }, [error, setMessage, setCustomErrors]);

    useDidUpdate(() => {
      if (success)
        setMessage({
          title: t('title.saved'),
          message: t('message.success'),
          type: 'success',
        });
    }, [success]);

    useEffect(() => {
      if (error || success) {
        const scrollableParent = getScrollParent(containerRef.current);
        scrollableParent.scrollTo(
          0,
          Math.max(0, containerRef.current.offsetTop - 60)
        );
      }
    }, [error, success]);

    return (
      <div ref={containerRef}>
        <Message
          type={message.type}
          title={message.title}
          message={message.message}
          onClose={() => setMessage({ type: message.type })}
          className={classes.notificationMessage}
        />
        <Component {...props} customErrors={customErrors} />
      </div>
    );
  };

export default withFormMessage;
