import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles()((theme) => ({
  sponsors: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  sponsorsRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginBottom: theme.spacing(3),
  },
  sponsorsRowItem: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '359px',
    height: '165px',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 0px 28px #00000019',
    border: '1px solid #70707019',
    borderRadius: theme.spacing(1),
    alignItems: 'center',
    margin: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 0),
    },
  },
  internalSponsor: {
    backgroundColor: '#FFFFFF',
    borderRadius: '6px',
    boxShadow: '0px 3px 6px #0000001A',
    height: '101px',
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backgroundSponsor: {
    maxHeight: '44px',
    maxWidth: '144px',
    padding: theme.spacing(1, 0),
    marginBottom: theme.spacing(1.5),
    opacity: '1',
  },
  noInternalSponsor: {
    backgroundImage: null,
    backgroundColor: '#FFFFFF',
    borderRadius: '6px',
    boxShadow: '0px 3px 6px #0000001A',
    height: '110px',
    width: '203px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1.5),
    aspectRatio: '135 / 76',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2, 0),
    },
  },
  noBackgroundSponsor: {
    padding: theme.spacing(0, 4.25),
  },
  sponsorsLink: {
    width: '55%',
    height: 'auto',
  },
  divider: {
    marginBottom: theme.spacing(4),
  },
}));

const SponsorsB = ({ sponsors }) => {
  const { classes } = useStyles();
  const sponsorsArray = sponsors?.map((element) => {
    if (element?.content?.length > 0)
      return (
        <>
          <Typography variant="h3" align="center">
            {element?.title}
          </Typography>
          <div className={classes.sponsorsRow}>
            {element?.content?.map((item) => {
              if (element.hasBackground) {
                const sponsorItem = (
                  <div
                    style={{ backgroundImage: `url('${item?.background}')` }}
                    className={classes.sponsorsRowItem}
                  >
                    <div className={classes.internalSponsor}>
                      <img
                        className={classes.backgroundSponsor}
                        src={item?.logo}
                        width="auto"
                        height="auto"
                        alt={item.name}
                      />
                      <a href={`https://${item?.link}`}>{item?.link}</a>
                    </div>
                  </div>
                );
                return sponsorItem;
              }
              const sponsorItem = (
                <div className={classes.noInternalSponsor}>
                  <img
                    className={classes.noBackgroundSponsor}
                    src={item?.logo}
                    width="85%"
                    height="auto"
                    alt={item?.name}
                  />
                </div>
              );
              return sponsorItem;
            })}
          </div>
        </>
      );
    return <> </>;
  });

  return (
    <div className={classes.sponsors}>
      {sponsors?.length > 0 && (
        <>
          <Divider className={classes.divider} />
          {sponsorsArray}
        </>
      )}
    </div>
  );
};

SponsorsB.propTypes = {
  sponsors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      size: PropTypes.string,
      hasBackground: PropTypes.string,
      clickOnLogoRedirects: PropTypes.bool,
      showLink: PropTypes.bool,
      content: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          background: PropTypes.string,
          logo: PropTypes.string,
          link: PropTypes.string,
        })
      ),
    })
  ),
};

SponsorsB.defaultProps = {
  sponsors: [],
};

export default SponsorsB;
