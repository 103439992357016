import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import Control from 'eventtia-ui-components/lib/Control';

const RHFControl = ({ name, ...other }) => {
  const { control } = useFormContext();
  const defultValue = [];

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { ref, value, onChange, ...field },
        fieldState: { error },
      }) => (
        <Control
          {...field}
          inputRef={ref}
          id={name}
          value={value || defultValue}
          handleChange={onChange}
          error={error?.message}
          {...other}
        />
      )}
    />
  );
};

RHFControl.propTypes = {
  name: PropTypes.string.isRequired,
};

export default RHFControl;
