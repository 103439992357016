const pageProperties = {
  registrationLink: 'https://inserteunlink.com/imagen.png',
  headerLogoUrl:
    'https://s3.amazonaws.com/eventtia-stage/event_files/94755/original/logoabsolut16576400881657640088.png?1657640088',
  bannerUrl:
    'https://s3.amazonaws.com/eventtia-stage/event_files/94757/original/fondoabsolut16576409891657640989.png?1657640989',
  colors: {
    primary: '#0033A0',
    secondary: '#D4318D',
  },
  metadata: {
    registeredPeople: 512,
    whiteLabel: true,
    availableLanguages: ['en', 'fr'],
    eventType: 'Mixed',
    location: '400 Liberty Avenue, Brooklyn, NY 11207. United States',
    map: 'https://s3.amazonaws.com/eventtia-stage/event_files/94768/original/map16578442301657844230.png?1657844230',
    shareLink: 'https://inserteunlink.com/imagen.png',
  },
  dates: {
    eventStartDate: '2021-11-11T00:00:00.000-05:00',
    eventEndDate: '2022-11-11T12:00:00.000-05:00',
    registrationEndDate: '2021-11-11T00:00:00.000-05:00',
  },
  eventName: {
    en: 'Inside Absolut: sustainability mission and latest launches',
    fr: '...',
  },
  description: {
    title: {
      en: 'Create a better tomorrow, tonight!',
      fr: '...',
    },
    content: {
      en: "Sustainability has been part of Absolut's mission for many years...",
      fr: '...',
    },
  },
  sponsors: [
    {
      title: 'OUR PARTNERS',
      size: 'large',
      hasBackground: true,
      hasLogo: true,
      clickOnLogoRedirectsToLink: true,
      showLink: true,
      content: [
        {
          name: 'InVision',
          background:
            'https://s3.amazonaws.com/eventtia-stage/event_files/94763/original/invisionstudioheader1024x6762x16578176421657817642.png?1657817642',
          logo: 'https://s3.amazonaws.com/eventtia-stage/event_files/94769/original/invisionlogo16581819381658181938.png?1658181938',
          link: 'invisionlink.com',
        },
        {
          name: 'HashiCorp',
          background:
            'https://s3.amazonaws.com/eventtia-stage/event_files/94764/original/dockerbanner2x16578176571657817657.png?1657817657',
          logo: 'https://s3.amazonaws.com/eventtia-stage/event_files/94756/original/logohashi16576401071657640107.png?1657640107',
          link: 'hashicorplink.com',
        },
        {
          name: 'Docker',
          background:
            'https://s3.amazonaws.com/eventtia-stage/event_files/94764/original/dockerbanner2x16578176571657817657.png?1657817657',
          logo: 'https://s3.amazonaws.com/eventtia-stage/event_files/94744/original/logodocker16576395291657639529.png?1657639529',
          link: 'dockerlink.com',
        },
      ],
    },
    {
      title: 'CONTRIBUTORS',
      size: 'medium',
      hasBackground: false,
      hasLogo: true,
      clickOnLogoRedirectsToLink: true,
      showLink: false,
      content: [
        {
          name: 'Amazon Web Services',
          background: null,
          logo: 'https://s3.amazonaws.com/eventtia-stage/event_files/94743/original/logoaws2x16576395211657639521.png?1657639521',
          link: 'aws.com',
        },
        {
          name: 'GitHub',
          background: null,
          logo: 'https://s3.amazonaws.com/eventtia-stage/event_files/94747/original/logogithub2x16576395601657639560.png?1657639560',
          link: 'github.com',
        },
        {
          name: 'Microsoft',
          background: null,
          logo: 'https://s3.amazonaws.com/eventtia-stage/event_files/94752/original/logomicrosoft2x16576396211657639621.png?1657639621',
          link: 'microsoft.com',
        },
        {
          name: 'IBM',
          background: null,
          logo: 'https://s3.amazonaws.com/eventtia-stage/event_files/94750/original/logoibm2x16576396021657639602.png?1657639602',
          link: 'ibm.com',
        },
        {
          name: 'NVidia',
          background: null,
          logo: 'https://s3.amazonaws.com/eventtia-stage/event_files/94766/original/nvidialogo16578379091657837909.png?1657837909',
          link: 'nvidia.com',
        },
      ],
    },
  ],
  footer: {
    organizerLogo:
      'https://s3.amazonaws.com/eventtia-stage/event_files/94767/original/profileavatarpng16578410761657841076.png?1657841076',
    organizerName: 'Pernod Ricard',
    organizerDescription: {
      en: 'Pernod Ricard is a convivial, responsible and successful global wine and spirits group.',
      fr: '...',
    },
    organizerInfo: {
      email: 'events@pernodricard.com',
      website: 'pernod-ricard.com/en',
    },
  },
};

export default pageProperties;
