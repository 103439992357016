import React, {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  getToken,
  isValidToken,
  setSession,
  getTokenContent,
} from '../../helpers/auth';
import fetchData from '../../api/fetchData';
import { getMainEntity } from '../../api/extractors';

const ACTIONS = {
  INIT: 0,
  LOGIN: 1,
  LOGOUT: 2,
  UPDATE_USER: 3,
};

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.INIT:
      return {
        isInitialized: true,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user,
      };

    case ACTIONS.LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case ACTIONS.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case ACTIONS.UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload.user },
      };
    default:
      return state;
  }
};

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const { t } = useTranslation('login');
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = getToken();

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const tokenContent = getTokenContent(accessToken);
        const userRequest = await fetchData('currentUser');
        const userInfo = getMainEntity('users', userRequest);

        dispatch({
          type: ACTIONS.INIT,
          payload: {
            isAuthenticated: !!userInfo,
            user: userInfo ? { ...userInfo, ...tokenContent } : null,
          },
        });
      } else
        dispatch({
          type: ACTIONS.INIT,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
    } catch (e) {
      dispatch({
        type: ACTIONS.INIT,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  const login = useCallback(async (accessToken) => {
    setSession(accessToken);
    const tokenContent = getTokenContent(accessToken);
    const userRequest = await fetchData('currentUser');
    const userInfo = getMainEntity('users', userRequest);

    if (!userInfo) {
      enqueueSnackbar(t('errors.unexpected'), {
        variant: 'error',
      });
      setSession(undefined);
      return;
    }

    enqueueSnackbar(t('messages.loggedIn'), {
      variant: 'success',
    });

    dispatch({
      type: ACTIONS.LOGIN,
      payload: {
        user: {
          ...userInfo,
          ...tokenContent,
        },
      },
    });
  }, []);

  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: ACTIONS.LOGOUT,
      payload: {},
    });
    enqueueSnackbar(t('messages.loggedOut'), {
      variant: 'success',
    });
  }, []);

  const updateUser = useCallback(async (user) => {
    dispatch({
      type: ACTIONS.UPDATE_USER,
      payload: {
        user,
      },
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      login,
      logout,
      updateUser,
    }),
    [state, login, logout, updateUser]
  );

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
