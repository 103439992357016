import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from 'tss-react/mui';
import { Box, Typography } from '@mui/material';
import FieldHelperText from 'eventtia-ui-components/lib/FieldHelperText';

const useStyles = makeStyles()((theme) => ({
  label: {
    fontSize: 12,
    color: theme.palette.darkGrey.main,
    marginBottom: theme.spacing(1),
  },
  colorsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  radioButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(3.5),
  },
  colorBox: {
    width: 32,
    height: 32,
    borderRadius: '4px',
    border: '1px solid',
    borderColor: theme.palette.darkGrey.light,
  },
}));

const ColorRadioButtons = ({ id, label, colors, value, onChange, error }) => {
  const { classes } = useStyles();

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const labelId = `${id}-label`;

  return (
    <>
      <Typography id={labelId} className={classes.label}>
        {label}
      </Typography>
      <RadioGroup
        className={classes.colorsContainer}
        aria-labelledby={labelId}
        value={value}
        onChange={handleChange}
      >
        {colors.map((color) => (
          <div className={classes.radioButtonContainer} key={color}>
            <Radio value={color} />
            <Box
              className={classes.colorBox}
              sx={{
                backgroundColor: color,
              }}
            />
          </div>
        ))}
      </RadioGroup>
      {error && (
        <FieldHelperText type="error" className={classes.errorMessage}>
          {error}
        </FieldHelperText>
      )}
    </>
  );
};

export default ColorRadioButtons;
