import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import MultilanguageOptionsInput from 'eventtia-ui-components/lib/MultilanguageOptionsInput';

const RHFMultilanguageOptionsInput = ({ name, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { ref, onChange, ...field },
        fieldState: { error },
      }) => (
        <MultilanguageOptionsInput
          {...field}
          id={name}
          handleChange={onChange}
          error={error?.message}
          value={field.value ?? []}
          {...other}
        />
      )}
    />
  );
};

RHFMultilanguageOptionsInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default RHFMultilanguageOptionsInput;
