import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import Drawer, { DrawerContent } from 'eventtia-ui-components/lib/Drawer';
import Button from 'eventtia-ui-components/lib/Button';
import FieldHelperText from 'eventtia-ui-components/lib/FieldHelperText';
import CodemirrorEditor from '../CodemirrorEditor';

const useStyles = makeStyles()((theme) => ({
  openDrawerButton: {
    padding: 0,
    marginLeft: theme.spacing(-2),
    marginBottom: theme.spacing(2),
  },
  errorMessage: {
    marginBottom: theme.spacing(2),
  },
  modalContent: {
    '& > div:nth-of-type(2)': {
      padding: 0,
    },
  },
}));

const CodemirrorDrawerInput = ({
  value,
  onChange,
  buttonLabel,
  dialogTitle,
  langExtension,
  error,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation('global');

  const viewRef = useRef(null);

  const [open, setOpen] = useState(false);
  const openDrawer = () => {
    setOpen(true);
  };
  const closeDrawer = () => {
    setOpen(false);
  };

  const changeValue = () => {
    onChange(viewRef.current?.state?.doc?.toString());
    closeDrawer();
  };

  return (
    <>
      <Button
        onClick={openDrawer}
        variant="tertiary"
        icon={<EditIcon />}
        className={classes.openDrawerButton}
        small
      >
        {buttonLabel}
      </Button>
      <Drawer open={open} anchor="right" extraWidth handleClose={closeDrawer}>
        {({ DrawerContentProps }) => (
          <DrawerContent
            title={dialogTitle}
            {...DrawerContentProps}
            WrapperProps={{
              className: classes.modalContent,
            }}
            primaryButton={
              <Button onClick={changeValue}>{t('actions.save')}</Button>
            }
            secondaryButton={
              <Button onClick={closeDrawer} variant="tertiary">
                {t('actions.discardChanges')}
              </Button>
            }
          >
            {error && (
              <FieldHelperText type="error" className={classes.errorMessage}>
                {error}
              </FieldHelperText>
            )}
            <CodemirrorEditor
              langExtension={langExtension}
              viewRef={viewRef}
              defaultValue={value}
            />
          </DrawerContent>
        )}
      </Drawer>
    </>
  );
};

CodemirrorDrawerInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  langExtension: PropTypes.func.isRequired,
  error: PropTypes.string,
};

CodemirrorDrawerInput.defaultProps = {
  error: undefined,
};

export default CodemirrorDrawerInput;
