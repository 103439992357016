import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import Card from 'eventtia-ui-components/lib/Card';
import EventtiaLogo from 'eventtia-ui-components/lib/EventtiaLogo';

const useStyles = makeStyles()((theme) => ({
  root: {
    maxWidth: '100%',
    width: theme.spacing(108),
  },
  titleContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    '& > h1': {
      fontWeight: 'bold',
    },
  },
  logo: {
    maxWidth: theme.spacing(20),
    color: theme.palette.secondary.light,
  },
  pipe: {
    margin: theme.spacing(0, 4),
    fontSize: theme.spacing(5),
    fontWeight: 300,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 2),
    },
  },
  contentRoot: {
    borderRadius: theme.spacing(3),
    margin: 0,
    padding: theme.spacing(6),
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    },
  },
  content: {
    minWidth: '45%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  imageContainer: {
    marginLeft: theme.spacing(6),
    width: theme.spacing(50),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      maxWidth: '100%',
    },
  },
}));

const LoggedOutLayoutContainer = ({ title, image, loading, children }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <EventtiaLogo className={classes.logo} />
        <span className={classes.pipe}>|</span>
        <Typography
          sx={{ mb: -1 }}
          color="white"
          variant="subtitle1"
          component="h1"
        >
          {title}
        </Typography>
      </div>

      <Card className={classes.contentRoot} loading={loading}>
        <div className={classes.content}>{children}</div>

        {image && (
          <Hidden mdDown>
            <div className={classes.imageContainer}>
              <img src={image} alt="content" />
            </div>
          </Hidden>
        )}
      </Card>
    </div>
  );
};

LoggedOutLayoutContainer.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

LoggedOutLayoutContainer.defaultProps = {
  image: undefined,
  loading: false,
};

export default LoggedOutLayoutContainer;
