import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { mergeLocales } from 'eventtia-ui-components/lib/locales';
import buttons from 'eventtia-ui-components/lib/locales/buttons';
import currency from 'eventtia-ui-components/lib/locales/currency';
import inputs from 'eventtia-ui-components/lib/locales/inputs';
import commercialPlans from 'eventtia-ui-components/lib/locales/commercialPlans';
import multilanguageInputs from 'eventtia-ui-components/lib/locales/multilanguageInputs';
import ticket from 'eventtia-ui-components/lib/locales/ticket';
import permissions from './permissions';
import attendees from './attendees';
import payments from './payments';
import account from './account';
import events from './events';
import global from './global';
import tags from './tags';
import team from './team';
import globalDashboard from './globalDashboard';
import routes from './routes';
import login from './login';
import signUp from './signUp';
import ticketTypes from './ticketTypes';
import composerTemplates from './composerTemplates';
import filters from './filters';
import userPlans from './userPlans';
import forgotPassword from './forgotPassword';
import emails from './emails';
import widgetSettings from './widgetSettings';
import features from './features';
import orders from './orders';

export const availableLanguages = ['en', 'es', 'fr', 'de'];
export const [defaultLanguage] = availableLanguages;

export const locales = mergeLocales(
  {
    attendees,
    payments,
    buttons,
    account,
    currency,
    events,
    global,
    login,
    tags,
    team,
    globalDashboard,
    routes,
    signUp,
    ticketTypes,
    inputs,
    permissions,
    composerTemplates,
    multilanguageInputs,
    commercialPlans,
    filters,
    userPlans,
    forgotPassword,
    emails,
    widgetSettings,
    features,
    orders,
    ticket,
  },
  availableLanguages
);

i18n.use(initReactI18next).init({
  resources: {
    ...locales,
  },
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
