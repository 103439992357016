import merge from 'lodash/merge';
import moment, { baseMoment } from '../../helpers/dates';
import getEndpoint from '../../helpers/endpoints';
import { getToken } from '../../helpers/auth';
import constants from '../../helpers/constants';

const { EVENTS } = constants;

export const CALL_API = 'Call API';

export const fetchConfig = {
  activateAccount: {
    types: ['ACTIVATE_REQUEST', 'ACTIVATE_SUCCESS', 'ACTIVATE_FAILURE'],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: data,
    }),
  },
  login: {
    types: ['LOGIN_REQUEST', 'LOGIN_SUCCESS', 'LOGIN_FAILURE'],
    options: (body) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body,
    }),
  },
  signUp: {
    types: ['SIGN_UP_REQUEST', 'SIGN_UP_SUCCESS', 'SIGN_UP_FAILURE'],
    options: (body) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body,
    }),
  },
  requestPasswordRecovery: {
    types: [
      'REQUEST_RECOVERY_PASSWORD_REQUEST',
      'REQUEST_RECOVERY_PASSWORD_SUCCESS',
      'REQUEST_RECOVERY_PASSWORD_FAILURE',
    ],
    options: (body) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body,
    }),
  },
  activateUserPassword: {
    types: [
      'CHANGE_PASSWORD_REQUEST',
      'CHANGE_PASSWORD_SUCCESS',
      'CHANGE_PASSWORD_FAILURE',
    ],
    options: (body) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body,
    }),
  },
  createEvent: {
    types: [
      'CREATE_EVENT_REQUEST',
      'CREATE_EVENT_SUCCESS',
      'CREATE_EVENT_FAILURE',
    ],
    options: (body) => ({
      headers: {
        Accept: '*/*',
      },
      method: 'POST',
      body,
    }),
  },
  deleteEvent: {
    types: [
      'DELETE_EVENT_REQUEST',
      'DELETE_EVENT_SUCCESS',
      'DELETE_EVENT_FAILURE',
    ],
    options: () => ({
      headers: {
        Accept: 'application/json',
      },
      method: 'DELETE',
    }),
  },
  updateEvent: {
    types: [
      'UPDATE_EVENT_REQUEST',
      'UPDATE_EVENT_SUCCESS',
      'UPDATE_EVENT_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: '*/*',
      },
      method: 'PUT',
      body: data,
    }),
  },
  updatePayments: {
    types: [
      'UPDATE_PAYMENTS_REQUEST',
      'UPDATE_PAYMENTS_SUCCESS',
      'UPDATE_PAYMENTS_FAILURE',
    ],
    options: (body) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body,
    }),
  },
  currentUser: {
    types: [
      'CURRENT_USER_REQUEST',
      'CURRENT_USER_SUCCESS',
      'CURRENT_USER_FAILURE',
    ],
  },
  users: {
    types: ['USERS_REQUEST', 'USERS_SUCCESS', 'USERS_FAILURE'],
  },
  user: {
    types: ['USER_REQUEST', 'USER_SUCCESS', 'USER_FAILURE'],
  },
  updateUser: {
    types: [
      'UPDATE_USER_REQUEST',
      'UPDATE_USER_SUCCESS',
      'UPDATE_USER_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: data,
    }),
  },
  createUser: {
    types: [
      'CREATE_USER_REQUEST',
      'CREATE_USER_SUCCESS',
      'CREATE_USER_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: data,
    }),
  },
  updateUserPassword: {
    types: [
      'UPDATE_USER_PASSWORD_REQUEST',
      'UPDATE_USER_PASSWORD_SUCCESS',
      'UPDATE_USER_PASSWORD_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: data,
    }),
  },
  getCommercialPlans: {
    types: [
      'COMMERCIAL_PLANS_REQUEST',
      'COMMERCIAL_PLANS_SUCCESS',
      'COMMERCIAL_PLANS_FAILURE',
    ],
  },
  activeSubscriptions: {
    types: [
      'SUBSCRIPTION_ACCOUNT_REQUEST',
      'SUBSCRIPTION_ACCOUNT_SUCCESS',
      'SUBSCRIPTION_ACCOUNT_FAILURE',
    ],
  },
  getCurrentSubscription: {
    types: [
      'CURRENT_SUBSCRIPTION_ACCOUNT_REQUEST',
      'CURRENT_SUBSCRIPTION_ACCOUNT_SUCCESS',
      'CURRENT_SUBSCRIPTION_ACCOUNT_FAILURE',
    ],
  },
  stripeOnboarding: {
    types: [
      'STRIPE_ONBOARDING_REQUEST',
      'STRIPE_ONBOARDING_SUCCESS',
      'STRIPE_ONBOARDING_FAILURE',
    ],
  },
  managePayment: {
    types: [
      'MANAGE_PAYMENT_REQUEST',
      'MANAGE_PAYMENT_SUCCESS',
      'MANAGE_PAYMENT_FAILURE',
    ],
    options: (body) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body,
    }),
  },
  accountDetails: {
    types: [
      'ACCOUNT_DETAILS_REQUEST',
      'ACCOUNT_DETAILS_SUCCESS',
      'ACCOUNT_DETAILS_FAILURE',
    ],
  },
  destroySubscription: {
    types: [
      'DESTROY_SUBSCRIPTION_REQUEST',
      'DESTROY_SUBSCRIPTION_SUCCESS',
      'DESTROY_SUBSCRIPTION_FAILURE',
    ],
    options: {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    },
  },
  createSubscription: {
    types: [
      'CREATE_SUBSCRIPTION_REQUEST',
      'CREATE_SUBSCRIPTION_SUCCESS',
      'CREATE_SUBSCRIPTION_FAILURE',
    ],
    options: (body) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body,
    }),
  },
  deleteUser: {
    types: [
      'DELETE_USER_REQUEST',
      'DELETE_USER_SUCCESS',
      'DELETE_USER_FAILURE',
    ],
    options: {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    },
  },
  attendee: {
    types: ['ATTENDEE_REQUEST', 'ATTENDEE_SUCCESS', 'ATTENDEE_FAILURE'],
  },
  attendees: {
    types: ['ATTENDEES_REQUEST', 'ATTENDEES_SUCCESS', 'ATTENDEES_FAILURE'],
    filters: {
      status: { clearable: false },
      search: { clearable: false },
    },
  },
  createTicketType: {
    types: [
      'CREATE_TICKET_TYPE_REQUEST',
      'CREATE_TICKET_TYPE_SUCCESS',
      'CREATE_TICKET_TYPE_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: data,
    }),
  },
  updateTicketType: {
    types: [
      'UPDATE_TICKET_TYPE_REQUEST',
      'UPDATE_TICKET_TYPE_SUCCESS',
      'UPDATE_TICKET_TYPE_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: data,
    }),
  },
  deleteTicketType: {
    types: [
      'DELETE_TICKET_TYPE_REQUEST',
      'DELETE_TICKET_TYPE_SUCCESS',
      'DELETE_TICKET_TYPE_FAILURE',
    ],
    options: () => ({
      headers: {
        Accept: 'application/json',
      },
      method: 'DELETE',
    }),
  },
  createField: {
    types: [
      'CREATE_FIELD_REQUEST',
      'CREATE_FIELD_SUCCESS',
      'CREATE_FIELD_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: '*/*',
      },
      method: 'POST',
      body: data,
    }),
  },
  updateField: {
    types: [
      'UPDATE_FIELD_REQUEST',
      'UPDATE_FIELD_SUCCESS',
      'UPDATE_FIELD_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: '*/*',
      },
      method: 'PUT',
      body: data,
    }),
  },
  reorderFields: {
    types: [
      'REORDER_FIELDS_REQUEST',
      'REORDER_FIELDS_SUCCESS',
      'REORDER_FIELDS_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: data,
    }),
  },
  associateField: {
    types: [
      'ASSOCIATE_FIELD_REQUEST',
      'ASSOCIATE_FIELD_SUCCESS',
      'ASSOCIATE_FIELD_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { data },
    }),
  },
  unassociateField: {
    types: [
      'UNASSOCIATE_FIELD_REQUEST',
      'UNASSOCIATE_FIELD_SUCCESS',
      'UNASSOCIATE_FIELD_FAILURE',
    ],
    options: () => ({
      headers: {
        Accept: 'application/json',
      },
      method: 'DELETE',
    }),
  },
  deleteField: {
    types: [
      'DELETE_FIELD_REQUEST',
      'DELETE_FIELD_SUCCESS',
      'DELETE_FIELD_FAILURE',
    ],
    options: () => ({
      headers: {
        Accept: 'application/json',
      },
      method: 'DELETE',
    }),
    passToAction: () => ({
      additionalReduction: (entities, action) => {
        const { fields } = entities;
        const { orderedFields } = action.response.meta;
        Object.keys(orderedFields).forEach((fieldId) => {
          fields[fieldId].order = orderedFields[fieldId];
        });
        return entities;
      },
    }),
  },
  ticketType: {
    types: [
      'TICKET_TYPE_REQUEST',
      'TICKET_TYPE_SUCCESS',
      'TICKET_TYPE_FAILURE',
    ],
  },
  ticketTypes: {
    types: [
      'TICKET_TYPES_REQUEST',
      'TICKET_TYPES_SUCCESS',
      'TICKET_TYPES_FAILURE',
    ],
    fetchAllPages: true,
  },
  fields: {
    types: ['FIELDS_REQUEST', 'FIELDS_SUCCESS', 'FIELDS_FAILURE'],
    fetchAllPages: true,
  },
  ticketTypeFields: {
    types: [
      'TICKET_TYPE_FIELDS_REQUEST',
      'TICKET_TYPE_FIELDS_SUCCESS',
      'TICKET_TYPE_FIELDS_FAILURE',
    ],
    fetchAllPages: true,
  },
  pricingTiers: {
    types: [
      'PRICING_TIERS_REQUEST',
      'PRICING_TIERS_SUCCESS',
      'PRICING_TIERS_FAILURE',
    ],
  },
  createPricingTier: {
    types: [
      'CREATE_PRICING_TIER_REQUEST',
      'CREATE_PRICING_TIER_SUCCESS',
      'CREATE_PRICING_TIER_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { data },
    }),
  },
  updatePricingTier: {
    types: [
      'UPDATE_PRICING_TIER_REQUEST',
      'UPDATE_PRICING_TIER_SUCCESS',
      'UPDATE_PRICING_TIER_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: { data },
    }),
  },
  deletePricingTier: {
    types: [
      'DELETE_PRICING_REQUEST',
      'DELETE_PRICING_SUCCESS',
      'DELETE_PRICING_FAILURE',
    ],
    options: () => ({
      headers: {
        Accept: 'application/json',
      },
      method: 'DELETE',
    }),
  },
  createAttendee: {
    types: [
      'CREATE_ATTENDEE_REQUEST',
      'CREATE_ATTENDEE_SUCCESS',
      'CREATE_ATTENDEE_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
      },
      method: 'POST',
      body: data,
    }),
  },
  deleteAttendee: {
    types: [
      'DELETE_ATTENDEE_REQUEST',
      'DELETE_ATTENDEE_SUCCESS',
      'DELETE_ATTENDEE_FAILURE',
    ],
    options: () => ({
      headers: {
        Accept: 'application/json',
      },
      method: 'DELETE',
    }),
  },
  updateCurrentAttendee: {
    types: [
      'UPDATE_CURRENT_ATTENDEE_REQUEST',
      'UPDATE_CURRENT_ATTENDEE_SUCCESS',
      'UPDATE_CURRENT_ATTENDEE_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
      },
      method: 'PUT',
      body: data,
    }),
  },
  currentEvent: {
    types: [
      'CURRENT_EVENT_REQUEST',
      'CURRENT_EVENT_SUCCESS',
      'CURRENT_EVENT_FAILURE',
    ],
  },
  monthEvents: {
    types: [
      'MONTH_EVENTS_REQUEST',
      'MONTH_EVENTS_SUCCESS',
      'MONTH_EVENTS_FAILURE',
    ],
    fetchAllPages: true,
  },
  events: {
    types: ['EVENTS_REQUEST', 'EVENTS_SUCCESS', 'EVENTS_FAILURE'],
    filters: {
      status: { clearable: false },
      search: { clearable: false },
      datesRange: { type: 'StartAndEndDate' },
    },
    parseFilters: ({ datesRange, status, search, ...filters }) => {
      const result = {
        ...filters,
        name: search,
        startDateUpperBound: datesRange?.startDate?.upper || undefined,
        startDateLowerBound: datesRange?.startDate?.lower || undefined,
        endDateUpperBound: datesRange?.endDate?.upper || undefined,
        endDateLowerBound: datesRange?.endDate?.lower || undefined,
      };
      switch (status) {
        case EVENTS.TABS.TEMPLATES:
          result.templates = true;
          break;
        case EVENTS.TABS.PAST:
          result.endDateUpperBound = baseMoment
            .min(moment(), moment(result.endDateUpperBound))
            .format();
          result.order = 'end_date desc';
          break;
        case EVENTS.TABS.CURRENT:
          result.endDateLowerBound = baseMoment
            .max(moment(), moment(result.endDateLowerBound))
            .format();
          result.order = 'start_date asc';
          break;
        default:
          result.order = 'start_date asc';
      }
      return result;
    },
  },
  countries: {
    types: ['COUNTRIES_REQUEST', 'COUNTRIES_SUCCESS', 'COUNTRIES_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  regions: {
    types: ['REGIONS_REQUEST', 'REGIONS_SUCCESS', 'REGIONS_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  cities: {
    types: ['CITIES_REQUEST', 'CITIES_SUCCESS', 'CITIES_FAILURE'],
    fetchAllPages: true,
    auth: false,
  },
  eventEmails: {
    types: [
      'EVENT_EMAILS_REQUEST',
      'EVENT_EMAILS_SUCCESS',
      'EVENT_EMAILS_FAILURE',
    ],
    fetchAllPages: true,
  },
  ticketTypeEmails: {
    types: [
      'TICKET_TYPE_EMAILS_REQUEST',
      'TICKET_TYPE_EMAILS_SUCCESS',
      'TICKET_TYPE_EMAILS_FAILURE',
    ],
    fetchAllPages: true,
  },
  createEventEmail: {
    types: [
      'CREATE_EVENT_EMAIL_REQUEST',
      'CREATE_EVENT_EMAIL_SUCCESS',
      'CREATE_EVENT_EMAIL_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { data },
    }),
  },
  updateEventEmail: {
    types: [
      'UPDATE_EVENT_EMAIL_REQUEST',
      'UPDATE_EVENT_EMAIL_SUCCESS',
      'UPDATE_EVENT_EMAIL_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: { data },
    }),
  },
  deleteEventEmail: {
    types: [
      'DELETE_EVENT_EMAIL_REQUEST',
      'DELETE_EVENT_EMAIL_SUCCESS',
      'DELETE_EVENT_EMAIL_FAILURE',
    ],
    options: {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    },
  },
  createEventEmailLanguage: {
    types: [
      'CREATE_EVENT_EMAIL_LANGUAGE_REQUEST',
      'CREATE_EVENT_EMAIL_LANGUAGE_SUCCESS',
      'CREATE_EVENT_EMAIL_LANGUAGE_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { data },
    }),
  },
  updateEventEmailLanguage: {
    types: [
      'UPDATE_EVENT_EMAIL_LANGUAGE_REQUEST',
      'UPDATE_EVENT_EMAIL_LANGUAGE_SUCCESS',
      'UPDATE_EVENT_EMAIL_LANGUAGE_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: { data },
    }),
  },
  deleteEventEmailLanguage: {
    types: [
      'DELETE_EVENT_EMAIL_LANGUAGE_REQUEST',
      'DELETE_EVENT_EMAIL_LANGUAGE_SUCCESS',
      'DELETE_EVENT_EMAIL_LANGUAGE_FAILURE',
    ],
    options: {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    },
  },
  createEmailSenderSettings: {
    types: [
      'CREATE_EMAIL_SENDER_SETTINGS_REQUEST',
      'CREATE_EMAIL_SENDER_SETTINGS_SUCCESS',
      'CREATE_EMAIL_SENDER_SETTINGS_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: { data },
    }),
  },
  updateEmailSenderSettings: {
    types: [
      'UPDATE_EMAIL_SENDER_SETTINGS_REQUEST',
      'UPDATE_EMAIL_SENDER_SETTINGS_SUCCESS',
      'UPDATE_EMAIL_SENDER_SETTINGS_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: { data },
    }),
  },
  deleteEmailSenderSettings: {
    types: [
      'DELETE_EMAIL_SENDER_SETTINGS_REQUEST',
      'DELETE_EMAIL_SENDER_SETTINGS_SUCCESS',
      'DELETE_EMAIL_SENDER_SETTINGS_FAILURE',
    ],
    options: {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    },
  },
  widgetSettings: {
    types: [
      'WIDGET_SETTINGS_REQUEST',
      'WIDGET_SETTINGS_SUCCESS',
      'WIDGET_SETTINGS_FAILURE',
    ],
  },
  updateWidgetSettings: {
    types: [
      'UPDATE_WIDGET_SETTINGS_REQUEST',
      'UPDATE_WIDGET_SETTINGS_SUCCESS',
      'UPDATE_WIDGET_SETTINGS_FAILURE',
    ],
    options: ({ data }) => ({
      method: 'PUT',
      body: data,
    }),
  },
  createArea: {
    types: [
      'CREATE_AREA_REQUEST',
      'CREATE_AREA_SUCCESS',
      'CREATE_AREA_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: data,
    }),
  },
  updateArea: {
    types: [
      'UPDATE_AREA_REQUEST',
      'UPDATE_AREA_SUCCESS',
      'UPDATE_AREA_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: data,
    }),
  },
  deleteArea: {
    types: [
      'DELETE_AREA_REQUEST',
      'DELETE_AREA_SUCCESS',
      'DELETE_AREA_FAILURE',
    ],
    options: () => ({
      headers: {
        Accept: 'application/json',
      },
      method: 'DELETE',
    }),
  },
  areas: {
    types: ['GET_AREAS_REQUEST', 'GET_AREAS_SUCCESS', 'GET_AREAS_FAILURE'],
  },
  area: {
    types: ['GET_AREA_REQUEST', 'GET_AREA_SUCCESS', 'GET_AREA_FAILURE'],
  },
  userAreas: {
    types: [
      'GET_USER_AREAS_REQUEST',
      'GET_USER_AREAS_SUCCESS',
      'GET_USER_AREAS_FAILURE',
    ],
  },
  totalUsersInAreas: {
    types: [
      'GET_TOTAL_USERS_IN_AREAS_REQUEST',
      'GET_TOTAL_USERS_IN_AREAS_SUCCESS',
      'GET_TOTAL_USERS_IN_AREAS_FAILURE',
    ],
  },
  totalEventsInAreas: {
    types: [
      'GET_TOTAL_EVENTS_IN_AREAS_REQUEST',
      'GET_TOTAL_EVENTS_IN_AREAS_SUCCESS',
      'GET_TOTAL_EVENTS_IN_AREAS_FAILURE',
    ],
  },
  roles: {
    types: ['GET_ROLES_REQUEST', 'GET_ROLES_SUCCESS', 'GET_ROLES_FAILURE'],
  },
  createRole: {
    types: [
      'CREATE_ROLE_REQUEST',
      'CREATE_ROLE_SUCCESS',
      'CREATE_ROLE_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: data,
    }),
  },
  getRole: {
    types: ['GET_ROLE_REQUEST', 'GET_ROLE_SUCCESS', 'GET_ROLE_FAILURE'],
  },
  updateRole: {
    types: [
      'UPDATE_ROLE_REQUEST',
      'UPDATE_ROLE_SUCCESS',
      'UPDATE_ROLE_FAILURE',
    ],
    options: ({ data }) => ({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: data,
    }),
  },
  deleteRole: {
    types: [
      'DELETE_ROLE_REQUEST',
      'DELETE_ROLE_SUCCESS',
      'DELETE_ROLE_FAILURE',
    ],
    options: {
      headers: {
        Accept: 'application/json',
      },
      method: 'DELETE',
    },
  },
  rolePermissions: {
    types: [
      'GET_ROLE_PERMISSIONS_REQUEST',
      'GET_ROLE_PERMISSIONS_SUCCESS',
      'GET_ROLE_PERMISSIONS_FAILURE',
    ],
  },
  permissions: {
    types: [
      'GET_PERMISSIONS_REQUEST',
      'GET_PERMISSIONS_SUCCESS',
      'GET_PERMISSIONS_FAILURE',
    ],
    fetchAllPages: true,
  },
  searchUser: {
    types: [
      'SEARCH_USERS_REQUEST',
      'SEARCH_USERS_SUCCESS',
      'SEARCH_USERS_FAILURE',
    ],
  },
};

export const getDefaultOptions = () => {
  const token = getToken();
  const defaultOptions = {
    headers: {
      Accept: 'application/json',
    },
  };
  if (token) defaultOptions.headers.Authorization = `bearer ${token}`;
  return defaultOptions;
};

export default (key, params, page) => (dispatch, state) => {
  const config = fetchConfig[key];
  const [, successType] = config.types;

  let filters;
  if (typeof state === 'function') filters = state().filters[key];
  else filters = state?.filters[key];

  // return success if fetch should be skipped
  if (typeof config.skip === 'function' && config.skip(params))
    return { type: successType };

  const filterParams = config.parseFilters
    ? config.parseFilters(filters)
    : filters;
  if (typeof page === 'number') filterParams['page[number]'] = page;
  let { options, passToAction } = config;
  if (typeof options === 'function') options = options(params);
  if (typeof passToAction === 'function') passToAction = passToAction(params);

  const requestDetails = {
    types: config.types,
    endpoint:
      config.fetchAllPages || typeof page !== 'string'
        ? getEndpoint(key, params, filterParams)
        : page,
    passToAction: passToAction || {},
    options: merge(getDefaultOptions(), options),
  };

  if (config.fetchAllPages) requestDetails.fetchAllPages = true;

  return dispatch({
    [CALL_API]: requestDetails,
  });
};
