import { LOGOUT } from '../../actions/app';
import { fetchConfig } from '../../actions/callApi';
import { setToken, removeToken } from '../../helpers/auth';

const [, loginSuccess] = fetchConfig.login.types;
const [, activateSuccess] = fetchConfig.activateAccount.types;

export default () => (next) => (action) => {
  switch (action.type) {
    case loginSuccess: {
      if (!action.response) break;

      const { token: authToken } = action.response;

      setToken(authToken);

      break;
    }
    case activateSuccess: {
      if (!action.response) break;

      const { token: authToken } = action.response.meta;

      setToken(authToken);

      break;
    }
    case LOGOUT: {
      removeToken();

      break;
    }
    default:
      break;
  }

  return next(action);
};
